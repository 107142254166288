import { FC } from 'react';
import Box, { BoxProps } from '../Box';
import { styled } from '@mui/material/styles';

const AppLayoutContentFooterRoot = styled(Box, {
  name: 'AppLayoutContentFooter',
  slot: 'Footer',
})(() => ({
  display: 'flex',
  flex: '0 1 auto',
}));

export type AppLayoutContentFooterProps = BoxProps;

/**
 * @deprecated
 * @constructor
 */
const AppLayoutContentFooter: FC<AppLayoutContentFooterProps> = (props) => {
  return <AppLayoutContentFooterRoot {...props} />;
};

export default AppLayoutContentFooter;
