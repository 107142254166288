import { useEffect, useState } from 'react';

const useApplePay = () => {
  const [ isAvailable, setIsAvailable ] = useState<boolean>();
  const { ApplePaySession } = (window as any);

  useEffect(() => {
    if (ApplePaySession) {
      try {
        if (ApplePaySession.canMakePayments()) {
          // console.log("You can pay with ApplePay.");
          setIsAvailable(true);
        } else {
          // console.log("Unable to pay using ApplePay.");
          setIsAvailable(false);
        }
      } catch (err) {
        // console.error("Error determining readiness to use ApplePay: ", err);
        setIsAvailable(false);
      }
    } else {
      // console.log("ApplePay API not available.");
      setIsAvailable(false);
    }
  }, [ ApplePaySession ]);

  return isAvailable;
};

export default useApplePay;
