import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { APPBAR_EXT_HEIGHT, APPBAR_INT_HEIGHT } from '../constants/layout';

export interface AppLayoutHeaderProps {
  /**
   * Increase top offset to accommodate the top internal bar?
   */
  hasInternalBar?: boolean;

  /**
   * Color of the background.
   */
  color?: 'internal' | 'white';
}

const AppLayoutHeaderRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'Header',
  shouldForwardProp: (prop) => prop !== 'hasInternalBar' && prop !== 'color',
})<AppLayoutHeaderProps>(({ theme, hasInternalBar, color }) => ({
  display: 'flex',
  alignItems: 'center',
  zIndex: theme.zIndex.drawer,
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    backgroundColor: color === 'internal' ? theme.palette.background.internal : theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    top: hasInternalBar ? APPBAR_INT_HEIGHT + APPBAR_EXT_HEIGHT.md : APPBAR_EXT_HEIGHT.md,
  },
}));

/**
 * @deprecated
 */
const AppLayoutHeader: FC<AppLayoutHeaderProps> = ({ color = 'white', children, hasInternalBar = false, ...other }) => {
  return (
    <AppLayoutHeaderRoot hasInternalBar={hasInternalBar} color={color} {...other}>
      {children}
    </AppLayoutHeaderRoot>
  );
};

export default AppLayoutHeader;
