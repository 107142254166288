export default (language: string) => {
  switch (language) {
    case 'cs':
      return 'CZ_cs';
    case 'en':
      return 'EN_us';
    default:
      return 'CZ_cs';
  }
};
