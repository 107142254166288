import React, { FC, MouseEvent, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link as ReactLink, useLocation } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@o2/ui/Box';
import Button from '@o2/ui/Button';
import Container from '@o2/ui/Container';
import FormGroup from '@o2/ui/FormGroup';
import Link from '@o2/ui/Link';
import Pane from '@o2/ui/Pane';
import Paper from '@o2/ui/Paper';
import Stack from '@o2/ui/Stack';
import Tab from '@o2/ui/Tab';
import Tabs from '@o2/ui/Tabs';
import Typography from '@o2/ui/Typography';
import ToggleButton from '@o2/ui/ToggleButton';
import FormHelperText from '@o2/ui/FormHelperText';
import HintSmall from '@o2/icons/HintSmall';

import StatusInfo from '@o2/icons/StatusInfo';
import StatusInfoSmall from '@o2/icons/StatusInfoSmall';
import PaymentOptions from '@pages/OnlineTopUp/PaymentOptions';

import useCodelists from '@hooks/useCodeLists';
import FormTextField from '@components/blocks/FormTextField';
import FormToggleButtonGroup from '@components/blocks/FormToggleButtonGroup';
import FormCheckbox from '@components/blocks/FormCheckbox';
import { getApiPath } from '@utilities/requests';

import useOnlineTopUp from './module/useOnlineTopUp';
import useAdobeAnalytics from '@hooks/useAdobeAnalytics';
import AlertAutomaticRechargeActivated from '@components/blocks/AlertAutomaticRechargeActivated';
import { getRePostQuery, redirectToStandaloneKahl } from '@utilities/redirects';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const OnlineTopUp: FC = () => {
  const baseUrl = '/online-topup';
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const { getItems } = useCodelists();
  const [ 
    deeplinkPolicy,
    amountToTopUpAmount1,
    amountToTopUpAmount2,
    amountToTopUpAmount3,
    amountToTopUpAmount4,
    mojeO2Web,
    mojeO2Mobile,
    isAutomaticRechargeVisible,
    isTopUpByCouponVisible,
  ] = getItems(
    'deeplink.policy',
    'amountToTopUpAmount1',
    'amountToTopUpAmount2',
    'amountToTopUpAmount3',
    'amountToTopUpAmount4',
    'deeplink.MojeO2Web',
    'deeplink.MojeO2Mobile',
    'automaticRechargeVisible',
    'topUpByCouponVisible'
  );
  const smDown = useMediaQuery(theme.breakpoints['down']('sm'));
  const mdDown = useMediaQuery(theme.breakpoints['down']('md'));
  const [ tab, setTab ] = useState<number>(0);
  const { handleSubmit, setValue, watch, isValid, errors, control, isLoggedIn, isAnonymous, getMsisdnByRole, isProcessing, getValues } =
    useOnlineTopUp();
  const { formFirstInteraction, pageLoaded } = useAdobeAnalytics();
  const [ isFormFirstInteraction, setFormFirstInteraction ] = useState(false);

  useEffect(() => {
    if (location.pathname) {
      const isCoupon = location.pathname.includes('coupon');

      if (isCoupon && isTopUpByCouponVisible === 'false') {
        redirectToStandaloneKahl('ID0010', 'recharging');
      }

      setTab(isCoupon ? 1 : 0);
      setValue('tab', isCoupon ? 1 : 0);
      setFormFirstInteraction(false);

      pageLoaded({
        pageId: isCoupon ? '1.3.2.2' : '1.3.2.1',
        pageTitle: isCoupon ? 'Dobíjení kupónem' : 'Online dobíjení',
      });
    }
  }, [ location.pathname ]);

  const handleShowRules = (event: MouseEvent) => {
    event.stopPropagation();
    window.open(getApiPath(deeplinkPolicy), '_blank');
  };

  const getButtonTitle = () => {
    if (!watch('automatic')) return t('topup.online.submitOnce');
    else if (watch('automatic') && isLoggedIn && !isAnonymous) return t('topup.online.submitAutomatic');
    else if (watch('automatic') && (!isLoggedIn || isAnonymous)) return t('topup.online.submitContinue');
  };

  const getPriceOptions = () => {
    return [
      {
        name: `${amountToTopUpAmount1} Kč`,
        value: amountToTopUpAmount1,
      },
      {
        name: `${amountToTopUpAmount2} Kč`,
        value: amountToTopUpAmount2,
      },
      {
        name: `${amountToTopUpAmount3} Kč`,
        value: amountToTopUpAmount3,
      },
      {
        name: `${amountToTopUpAmount4} Kč`,
        value: amountToTopUpAmount4,
      },
      {
        name: t('topup.online.customAmountToTopup'),
        value: 'custom',
      },
    ];
  };

  const handleBackToMojeO2 = () => {
    const webview = window.localStorage.getItem('webview');
    window.location.href = webview ? getApiPath(mojeO2Mobile) : getApiPath(`${mojeO2Web}${getMsisdnByRole()}`);
  };

  const handleFocus = () => {
    if (location.pathname) {
      const formType = location.pathname.includes('coupon') ? 'coupon' : 'payment';

      if (!isFormFirstInteraction) {
        setFormFirstInteraction(true);
        formFirstInteraction(formType);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Box my={5}>

        <Typography variant="h1">{t('topup.online.title')}</Typography>
        <Box my={4}>
          <Paper>
            <AlertAutomaticRechargeActivated />
            <Box p={{ sm: 0.5 }}>
              <Tabs value={tab} textVariant="h5">
                {tab === 0 && (
                  <Tab
                    sx={{ fontSize: theme.typography.h4 }}
                    label={t('topup.card.online')}
                    //@ts-expect-error waiting for https://jira.cz.o2/browse/FE-538
                    component={ReactLink}
                    to={`${baseUrl}${getRePostQuery()}`}
                    {...a11yProps(0)}
                  />
                )}
                {tab === 1 && (
                  <Tab
                    sx={{ fontSize: theme.typography.h4 }}
                    label={t('topup.card.coupon')}
                    //@ts-expect-error waiting for https://jira.cz.o2/browse/FE-538
                    component={ReactLink}
                    to={`${baseUrl}/coupon${getRePostQuery()}`}
                    {...a11yProps(1)}
                  />
                )}
              </Tabs>
              <Box mt={4}>
                <Box hidden={tab !== 0}>
                  <Box mb={2}>
                    <Typography variant="subtitle1">{t('topup.online.description')}</Typography>
                    <Stack direction="column" mt={2} spacing={2}>
                      <Box mb={2}>
                        <FormTextField
                          label={t('topup.online.phoneNumber.label')}
                          name="phoneNumber"
                          control={control}
                          placeholder={t('topup.online.phoneNumber.placeholder')}
                          fullWidth={smDown}
                          error={Boolean(errors.phoneNumber)}
                          errorText={errors.phoneNumber?.message ?? ''}
                          onFocus={handleFocus}
                        />
                      </Box>
                      <Box>
                        <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap">
                          <Box>
                            <Typography variant="body1" mb={3/8} fontWeight={600}>
                              {t('topup.online.chooseAmount')}
                            </Typography>
                            <FormToggleButtonGroup
                              name="priceOption"
                              control={control}
                              color="primary"
                              exclusive
                              orientation={smDown ? 'vertical' : 'horizontal'}
                              fullWidth={smDown}
                              sx={{
                                mb: 1,
                                mr: 1,
                              }}
                            >
                              {getPriceOptions().map((option) => (
                                <ToggleButton key={option.value} value={option.value} onFocus={handleFocus}>
                                  {option.name}
                                </ToggleButton>
                              ))}
                            </FormToggleButtonGroup>
                          </Box>
                          {watch('priceOption') === 'custom' && (
                            <Box pt={{ xs: 1, sm: 0 }}>
                              <FormTextField
                                name="amount"
                                control={control}
                                label={t('topup.online.amount.placeholder')}
                                fullWidth={mdDown}
                                error={Boolean(errors.amount)}
                                helperText={errors.amount ? '' : t('topup.online.amount.hint')}
                                errorText={errors.amount?.message}
                                onFocus={handleFocus}
                              />
                            </Box>
                          )}
                        </Stack>
                      </Box>
                      {isAutomaticRechargeVisible === 'true' && (
                        <Pane p={2} variant="outlined">
                          <Stack direction="row" spacing={2}>
                            <Box data-elm="1.3.2.1_070">
                              <HintSmall />
                            </Box>
                            <Box>
                              <Typography data-elm="1.3.2.1_069" variant="h6">
                                {t('topup.online.autoTopup.description')}
                              </Typography>
                              <Box mb={2}>
                                <FormGroup onFocus={handleFocus}>
                                  <FormCheckbox
                                    name="automatic"
                                    control={control}
                                    label={t('topup.online.autoTopup.showSwitcher')}
                                  />
                                </FormGroup>
                              </Box>
                              <Typography color={watch('automatic') ? 'default' : 'textSecondary'}>
                                {t('topup.online.autoTopup.automaticAmount.label')}
                              </Typography>
                              <Stack direction="row" gap={0.5} mb={2} alignItems="center">
                                <FormTextField
                                  name="automaticAmount"
                                  control={control}
                                  placeholder={t('topup.online.autoTopup.automaticAmount.placeholder')}
                                  fullWidth={smDown}
                                  error={Boolean(errors.automaticAmount)}
                                  helperText={
                                    errors.automaticAmount
                                      ? '' : t('topup.online.autoTopup.automaticAmount.hint')
                                  }
                                  errorText={errors.automaticAmount?.message}
                                  disabled={!watch('automatic')}
                                  onFocus={handleFocus}
                                />
                                <Typography
                                  color={watch('automatic') ? 'default' : 'textSecondary'}
                                  sx={{
                                    position: 'relative',
                                    bottom: '14px',
                                    left: '6px',
                                    whiteSpace: 'nowrap',
                                  }}>
                                  {t('topup.online.autoTopup.automaticAmount.currency')}
                                </Typography>
                              </Stack>
                              {!isLoggedIn && (
                                <Box>
                                  <Pane data-elm="1.3.2.1_059" color="primary" info="primary" variant="outlined" p={2}>
                                    <Stack direction="row" spacing={2}>
                                      <Box>{smDown ? <StatusInfoSmall /> : <StatusInfo />}</Box>
                                      <Box>
                                        {t('topup.online.needOTPDescription')}
                                      </Box>
                                    </Stack>
                                  </Pane>
                                </Box>
                              )}
                            </Box>
                          </Stack>
                        </Pane>
                      )}
                    </Stack>
                  </Box>
                  <Box mt={4}>
                    <PaymentOptions
                      control={control}
                      setValue={setValue}
                      error={Boolean(errors.payMethod)}
                      errorText={errors.payMethod?.message}
                      getValues={getValues}
                      isRecurrent={watch('automatic')}
                    />
                  </Box>
                </Box>
                <Box hidden={tab !== 1 || isTopUpByCouponVisible === 'false'}>
                  <Typography variant="subtitle1">{t('topup.coupon.description')}</Typography>
                  <Stack mt={2} direction="column" spacing={2}>
                    <Box>
                      <FormTextField
                        name="couponPhoneNumber"
                        control={control}
                        label={t('topup.coupon.phoneNumber.label')}
                        placeholder={t('topup.coupon.phoneNumber.placeholder')}
                        fullWidth={smDown}
                        error={Boolean(errors.couponPhoneNumber)}
                        errorText={errors.couponPhoneNumber?.message}
                        onFocus={handleFocus}
                      />
                    </Box>
                    <Box>
                      <FormTextField
                        name="coupon"
                        control={control}
                        label={t('topup.coupon.coupon.label')}
                        fullWidth={smDown}
                        error={Boolean(errors.coupon)}
                        errorText={errors.coupon?.message ?? ''}
                        onFocus={handleFocus}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Box mt={tab === 0 ? 3 : 5}>
                {tab === 0 && watch('automatic') && (
                  <Box mb={2}>
                    <FormGroup onFocus={handleFocus}>
                      <FormCheckbox
                        name="agreePolicy"
                        control={control}
                        label={<Trans i18nKey={'topup.online.agreePolicy.text'} components={{ a: <Link onClick={handleShowRules} /> }} />}
                      />
                      {Boolean(errors.agreePolicy) && <FormHelperText error>{errors.agreePolicy?.message ?? ''}</FormHelperText>}
                    </FormGroup>
                  </Box>
                )}
                {(tab === 0 || (tab === 1 && isTopUpByCouponVisible !== 'false')) && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
                    <Button
                      variant="contained"
                      disabled={
                        (
                          (tab === 1 && isTopUpByCouponVisible !== 'false') ? !isValid : false
                        ) ||
                        isProcessing
                      }
                      onClick={handleSubmit}
                      onFocus={handleFocus}
                    >
                      {getButtonTitle()}
                    </Button>
                    {isLoggedIn && (
                      <Button onClick={handleBackToMojeO2} onFocus={handleFocus}>{t('topup.online.backToMojeO2')}</Button>
                    )}
                  </Stack>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default OnlineTopUp;
