import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export function getToolbarUtilityClass(slot: string) {
  return generateUtilityClass('O2MobileAppBanner', slot);
}

const o2MobileAppBannerClasses = generateUtilityClasses('O2MobileAppBanner', [
  'root',
  'leftContainer',
  'leftContainerStack',
  'appButtonStack',
  'rightContainer',
  'rightContainerBox',
  'image',
  'qrCode',
]);

export default o2MobileAppBannerClasses;
