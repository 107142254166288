import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useTopUpByCard, useTopUpByCoupon, useGetCpRelations } from '@hooks/useAPI';
import useCodelists from '@hooks/useCodeLists';
import useAuth from '@hooks/useAuth';
import queryClient from '@state/reactQuery/queryClient';
import useAdobeAnalytics from '@hooks/useAdobeAnalytics';

import { ITopupByCardParams } from '@configurations/types';
import IFormInput, { ILocationState } from './types';
import { getRePostQuery } from '@utilities/redirects';

const useOnlineTopUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ locationState ] = useState<ILocationState | undefined>(location.state ? (location.state as ILocationState) : undefined);
  const { getItems } = useCodelists();
  const { hasRoleByName, isLoggedIn, isAnonymous, getDeviceIdWithoutPrefix } = useAuth();
  const [ 
    minimalAmountForAutomaticTopup,
    amountToTopUpAmount1,
    amountToTopUpAmount2,
    amountToTopUpAmount3,
    amountToTopUpAmount4,
  ] = getItems(
    'minimalAmountForAutomaticTopup',
    'amountToTopUpAmount1',
    'amountToTopUpAmount2',
    'amountToTopUpAmount3',
    'amountToTopUpAmount4'
  );
  const { t, i18n } = useTranslation();
  const onSuccessTopUpByCoupon = () => navigate(`/dobijeni/success${getRePostQuery()}`);
  const { formSubmit } = useAdobeAnalytics();
  const {
    mutation: { mutate: topUpByCoupon, isProcessing: couponIsProcessing },
  } = useTopUpByCoupon(onSuccessTopUpByCoupon);
  const {
    mutation: { mutate: topUpByCard, isProcessing: cardIsProcessing },
    queryData: topUpByCardData,
  } = useTopUpByCard();
  const {
    query: { data: cpRelationsData, isFetched: cpRelationsDataIsFetched },
  } = useGetCpRelations(isLoggedIn);
  const schema = yup
    .object({
      tab: yup.number(),
      couponPhoneNumber: yup.string().when('tab', {
        is: 1,
        then: yup
          .string()
          .matches(/^[0-9]{9,9}$/, t('topup.coupon.phoneNumber.badFormat'))
          .required(t('topup.coupon.phoneNumber.required')),
      }),
      coupon: yup.string().when('tab', {
        is: 1,
        then: yup
          .string()
          .required(t('topup.coupon.coupon.required')),
      }),
      phoneNumber: yup.string().when('tab', {
        is: 0,
        then: yup
          .string()
          .matches(/^[0-9]{9,9}$/, t('topup.online.phoneNumber.badFormat'))
          .required(t('topup.online.phoneNumber.required')),
      }),
      priceOption: yup.string().when('tab', {
        is: 0,
        then: yup.string().required(t('topup.online.phoneNumber.required')),
      }),
      amount: yup.string().when([ 'tab', 'priceOption' ], {
        is: (tabVal: number, priceOption: string) => priceOption === 'custom' && tabVal === 0,
        then: yup
          .string()
          .matches(/^[0-9]+$/, t('topup.online.amount.notValidNumber'))
          .required(t('topup.online.amount.required'))
          .test('outOfRange', t('topup.online.amount.outOfRange'), (val) => Number(val) <= 9999 && Number(val) >= 300),
      }),
      automatic: yup.boolean(),
      automaticAmount: yup.string().when([ 'tab', 'automatic' ], {
        is: (tabVal: number, automatic: boolean) => automatic === true && tabVal === 0,
        then: yup
          .string()
          .matches(/^[0-9]+$/, t('topup.online.autoTopup.automaticAmount.notValidNumber'))
          .required(t('topup.online.autoTopup.automaticAmount.required'))
          .test(
            'automaticOutOfRange',
            t('topup.online.autoTopup.automaticAmount.outOfRange'),
            (val) => Number(val) >= 50
          ),
      }),
      agreePolicy: yup.boolean().when([ 'tab', 'automatic' ], {
        is: (tabVal: number, automatic: boolean) => automatic === true && tabVal === 0,
        then: yup.boolean().required(t('topup.online.agreePolicy.required')),
      }),
      payMethod: yup.number().when('tab', {
        is: 0,
        then: yup.number().required(t('topup.online.choosePaymentMethod')),
      }),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    getValues,
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: {
      tab: 0,
      couponPhoneNumber: '',
      coupon: '',
      phoneNumber: locationState?.preFillForm ? topUpByCardData?.msisdn : '',
      priceOption: '',
      amount: '',
      automatic: locationState?.preFillForm ? topUpByCardData?.rechargeIfLow : false,
      automaticAmount: '',
      payMethod: locationState?.preFillForm ? topUpByCardData?.paymentMethod : undefined,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!locationState?.preFillForm) {
      queryClient.setQueryData('topUpByCard', undefined);
      queryClient.setQueryData('topUpByCardResponse', undefined);
      queryClient.setQueryData('topUpByCoupon', undefined);
      queryClient.setQueryData('topUpByCouponResponse', undefined);
    }
  }, [ locationState ]);

  useEffect(() => {
    if (cpRelationsDataIsFetched && isLoggedIn) {
      const msisdn = getMsisdnByRole();
      setValue('couponPhoneNumber', msisdn);
      !locationState?.preFillForm && setValue('phoneNumber', msisdn);
    }
  }, [ cpRelationsDataIsFetched, isLoggedIn ]);

  useEffect(() => {
    if (minimalAmountForAutomaticTopup) {
      setValue('automaticAmount', locationState?.preFillForm ? topUpByCardData?.rechargeIfLowValue : minimalAmountForAutomaticTopup);
    }
  }, [ minimalAmountForAutomaticTopup ]);

  useEffect(() => {
    // if preFillForm is false or undefined, set default priceOption according to DD (amountToTopUpAmount2)
    if (!locationState?.preFillForm && amountToTopUpAmount1) setValue('priceOption', amountToTopUpAmount2);

    // if preFillForm is true, set priceOption and amount from previous topUpByCard call
    if (locationState?.preFillForm && amountToTopUpAmount1 && amountToTopUpAmount2 && amountToTopUpAmount3 && amountToTopUpAmount4) {
      const priceOptions = [ amountToTopUpAmount1, amountToTopUpAmount2, amountToTopUpAmount3, amountToTopUpAmount4 ];

      if (priceOptions.includes(`${topUpByCardData?.amount}`)) {
        setValue('priceOption', topUpByCardData?.amount?.toString() ?? amountToTopUpAmount1);
      } else {
        setValue('priceOption', 'custom');
        setValue('amount', topUpByCardData?.amount?.toString() ?? '');
      }
    }
  }, [ minimalAmountForAutomaticTopup, amountToTopUpAmount1, amountToTopUpAmount2, amountToTopUpAmount3, amountToTopUpAmount4 ]);

  const getMsisdnByRole = () => {
    let msisdn = '';
    if (hasRoleByName('LOA1')) msisdn = getDeviceIdWithoutPrefix(cpRelationsData?.msisdn);
    else if (hasRoleByName('LOA0')) msisdn = getDeviceIdWithoutPrefix();

    return msisdn;
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (data.tab === 1) topUpByCoupon({ coupon: data.coupon, msisdn: data.couponPhoneNumber });
    if (data.tab === 0) {
      formSubmit(
        data.phoneNumber,
        data.priceOption === 'custom' ? Number(data.amount) : Number(data.priceOption),
        data.payMethod,
        false,
        data.automatic,
        (data.automatic ? Number(data.automaticAmount) : undefined),
        !!data.coupon,
      );
      topUpByCard(
        {
          msisdn: data.phoneNumber,
          amount: data.priceOption === 'custom' ? Number(data.amount) : Number(data.priceOption),
          paymentMethod: data.payMethod,
          language: i18n.language,
          rechargeIfLow: data.automatic,
          rechargeIfLowValue: data.automatic ? Number(data.automaticAmount) : undefined,
        },
        {
          onSettled: (_data: any, error: any, { msisdn }: ITopupByCardParams) => {
            if (error?.response?.status === 412) navigate(`/confirm-automatic-topup/${msisdn}${getRePostQuery()}`);
          },
        }
      );
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    watch,
    errors,
    isValid,
    control,
    isLoggedIn,
    isAnonymous,
    getMsisdnByRole,
    isProcessing: cardIsProcessing || couponIsProcessing,
    getValues,
  };
};

export default useOnlineTopUp;
