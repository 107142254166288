import React, { FC } from 'react';
import {
  Box,
  Chip,
  Typography,
} from '@o2/ui';
import MojeO2MobileAppBanner from '@o2/common-business/MojeO2MobileAppBanner';

const AppBanner: FC = () => {
  return (
    <MojeO2MobileAppBanner>
      <Chip color="secondary" label="Doporučujeme" />
      <Box mt={2}>
        <Typography mb={1.5} variant="h6">
          Stáhněte si mobilní aplikaci Moje O2
        </Typography>
        <Typography variant="subtitle2">V aplikaci získáte přehled o</Typography>
        <ul>
          <li>volných a vyčerpaných minutách, SMS a datech</li>
          <li>dobíjení a čerpání kreditu</li>
          <li>doplňkových službách (balíčky, roaming...)</li>
        </ul>
      </Box>
    </MojeO2MobileAppBanner>
  );
};

export default AppBanner;
