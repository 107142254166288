const flattenObject = (ob: object) => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i as keyof typeof ob] === 'object' && ob[i as keyof typeof ob] !== null) {
      const flatObject = flattenObject(ob[i as keyof typeof ob]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        // @ts-ignore
        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i as keyof typeof ob] = ob[i as keyof typeof ob];
    }
  }
  return toReturn;
};

export default flattenObject;
