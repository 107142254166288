import { useEffect, useState } from 'react';

interface IScript{
  isLoadEnd: boolean
  [key: string]: any
}

function useScript(resourceUrl: string, libName: string, async = true) {
  // The value "isLoadEnd" will always be "TRUE" after loading.
  // It doesn't matter if it's success or error.
  const [ lib, setLib ] = useState<IScript>({ isLoadEnd: false });

  useEffect(() => {
    const script = loadScript(resourceUrl, libName);

    document?.body?.appendChild(script);
    return () => {
      document?.body?.removeChild(script);
    };
  }, [ resourceUrl ]);

  const loadScript = (url: string, name: any) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = async;

    if (name) script.onload = () => setLib({ [name]: window[name], isLoadEnd: true });
    else script.onload = () => setLib({ isLoadEnd: true });
    script.onerror = (e: any) => {
      console.log(e); setLib({ isLoadEnd: true }); 
    };

    return script;
  };

  return lib;
}

export default useScript;
