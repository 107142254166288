import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@o2/ui/Box';
import Button from '@o2/ui/Button';
import Container from '@o2/ui/Container';
import Typography from '@o2/ui/Typography';
import InfoMessage from '@o2/common-business/InfoMessage';
import { useTranslation } from 'react-i18next';
import useCodeLists from '@hooks/useCodeLists';

const ConfirmAutomaticPaymentFail: FC = ({ ...props }) => {
  const { t } = useTranslation();
  const { getItems } = useCodeLists();
  const [ link ] = getItems('continueAutomaticFail');
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints['down']('sm'));

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box mb={3}>
          <InfoMessage
            variant="error"
            description={
              <Typography data-elm="1.3.2.3.2-001" variant="body1">
                {t('topup.automaticPaymentFail.tryAgainLater')}
              </Typography>
            }
            {...props}
          />
        </Box>
        <Button
          data-elm="1.3.2.3.2-002"
          fullWidth={smDown}
          size="small"
          color="primary"
          variant="text"
          onClick={() => window.location.href = link}
        >
          {t('topup.automaticPaymentFail.continue')}
        </Button>
      </Box>
    </Container>
  );
};

export default ConfirmAutomaticPaymentFail;
