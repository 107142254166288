import React, { FC, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
//import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, Stack, Pane } from '@o2/ui';
import useCodelists from '@hooks/useCodeLists';
import AppBanner from '@components/sections/AppBanner';
import StatusSuccess from '@o2/icons/StatusSuccess';
import StatusSuccessSmall from '@o2/icons/StatusSuccessSmall';
import { Theme } from '@mui/material/styles';

import queryClient from '@state/reactQuery/queryClient';
import kahl from '@configurations/constants/kahl';
import useAuth from '@hooks/useAuth';
import { getApiPath } from '@utilities/requests';

import { ITopupByCouponResponse, ITopupByCardResponse, ITopupByCardParams, ITopupByCouponParams } from '@configurations/types';
import useAdobeAnalytics from '@hooks/useAdobeAnalytics';
import { useGetCpRelations } from '@hooks/useAPI';

const TopUpSuccess: FC = () => {
  const webview = window.localStorage.getItem('webview');
  const { t } = useTranslation();
  //const navigate = useNavigate();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { getItems } = useCodelists();
  const { isLoggedIn, isAnonymous, hasRoleByName, getDeviceIdWithoutPrefix } = useAuth();
  const {
    query: { data: cpRelationsData },
  } = useGetCpRelations(isLoggedIn);
  const [ mojeO2Web, mojeO2Mobile ] = getItems('deeplink.MojeO2Web', 'deeplink.MojeO2Mobile');
  const topUpByCouponResponse = queryClient.getQueryData<ITopupByCouponResponse>('topUpByCouponResponse');
  const topUpByCoupon = queryClient.getQueryData<ITopupByCouponParams>('topUpByCoupon');
  const topUpByCardResponse = queryClient.getQueryData<ITopupByCardResponse>('topUpByCardResponse');
  const topUpByCard = queryClient.getQueryData<ITopupByCardParams>('topUpByCard');
  const { rechargingPaymentGatewayResult, pageLoaded } = useAdobeAnalytics();

  useEffect(() => {
    if (topUpByCardResponse) {
      queryClient.setQueryData('kahlIdStore', { kahlMessageId: kahl.returnPaymentGatewaySuccess, timestamp: Date.now() });
    }
  }, []);

  useEffect(() => {
    pageLoaded({
      pageId: '1.3.2.1.3',
      pageTitle: 'Potvrzení platby',
    });
    if (topUpByCardResponse && topUpByCard) {
      rechargingPaymentGatewayResult(
        true,
        topUpByCardResponse.paymentId,
        topUpByCard.msisdn,
        topUpByCard.amount,
        topUpByCard.paymentMethod,
        !!topUpByCard.rechargeEveryMonth,
        !!topUpByCard.rechargeIfLow,
        topUpByCard.rechargeIfLowValue,
        !!topUpByCouponResponse,
      );
    }
  }, [ topUpByCardResponse, topUpByCard ]);

  const getMsisdnByRole = () => {
    let msisdn = '';
    if (hasRoleByName('LOA1')) msisdn = getDeviceIdWithoutPrefix(cpRelationsData?.msisdn);
    else if (hasRoleByName('LOA0')) msisdn = getDeviceIdWithoutPrefix();

    return msisdn;
  };

  const handleBackToMojeO2 = () => {
    window.location.href = webview ? getApiPath(mojeO2Mobile) : getApiPath(`${mojeO2Web}${getMsisdnByRole()}`);
  };

  /*const handleTopUpAgain = () => {
    queryClient.setQueryData('topUpByCard', undefined);
    queryClient.setQueryData('topUpByCardResponse', undefined);
    queryClient.setQueryData('topUpByCoupon', undefined);
    queryClient.setQueryData('topUpByCouponResponse', undefined);
    navigate('/');
  };*/

  const getTitle = () => {
    if (topUpByCouponResponse) return t('topup.success.couponApplied', { amount: topUpByCouponResponse.voucherAmount });
    if (topUpByCardResponse) {
      if (topUpByCard?.rechargeIfLow) return t('topup.success.automaticTopupTitle');
      return t('topup.success.oneTimetopupTitle', { amount: topUpByCard?.amount });
    }
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box mb={3}>
          <Pane info="success" p={2}>
            <Stack direction="row" spacing={2}>
              <Box>{smDown ? <StatusSuccessSmall /> : <StatusSuccess />}</Box>
              <Box>
                <Typography variant="h5" mb={2}>
                  {getTitle()}
                </Typography>
                {topUpByCardResponse && topUpByCard?.rechargeIfLow && (
                  <Pane variant="outlined" p={2}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.2, sm: 1 }}>
                      <Typography>{t('topup.success.recurent.phoneNumber')}</Typography>
                      <Typography>
                        <strong>{topUpByCard?.msisdn}</strong>
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.2, sm: 1 }}>
                      <Typography>{t('topup.success.recurent.amount')}</Typography>
                      <Typography>
                        <strong>{`${topUpByCard?.amount} Kč`}</strong>
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.2, sm: 1 }}>
                      <Typography>{t('topup.success.recurent.limit')}</Typography>
                      <Typography>
                        <strong>{`${topUpByCard?.rechargeIfLowValue} Kč`}</strong>
                      </Typography>
                    </Stack>
                  </Pane>
                )}
                <Box mt={2}>
                  {(topUpByCardResponse || topUpByCouponResponse) && (
                    <Typography>
                      <Trans
                        i18nKey="topup.success.recurent.smsSended"
                        values={{ phonenumber: topUpByCard?.msisdn ?? topUpByCoupon?.msisdn }}
                      />
                    </Typography>
                  )}
                  {topUpByCardResponse && (topUpByCard?.rechargeIfLow || topUpByCard?.rechargeEveryMonth) && (
                    <Typography>
                      {t('topup.success.setAutomaticTopup.text') + ' '}
                      <a href={webview ? getApiPath(mojeO2Mobile) : getApiPath(`${mojeO2Web}${getMsisdnByRole()}`)}>
                        {t('topup.success.setAutomaticTopup.link')}
                      </a>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Stack>
          </Pane>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">
          {isLoggedIn && !isAnonymous && <Button onClick={handleBackToMojeO2}>{t('topup.success.back')}</Button>}
        </Stack>
        {!webview && (
          <Box mt={8}>
            <AppBanner />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default TopUpSuccess;
