import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Controller } from 'react-hook-form';

import Box from '@o2/ui/Box';
import FormGroup from '@o2/ui/FormGroup';
import Pane from '@o2/ui/Pane';
import Stack from '@o2/ui/Stack';
import Typography from '@o2/ui/Typography';
import Radio from '@o2/ui/Radio';
import FormHelperText from '@o2/ui/FormHelperText';
import CircularProgress from '@o2/ui/CircularProgress';

import useGooglePay from '@hooks/useGooglePay';
import useApplePay from '@hooks/useApplePay';
import { useGetPaymentMethods } from '@hooks/useAPI';

import IProps from './types';

const PaymentOptions: FC<IProps> = ({ control, setValue, error, errorText, getValues, isRecurrent }) => {
  const [ isPayMethodInit, setIsPayMethodInit ] = useState<boolean>(false);
  const { i18n, t } = useTranslation();
  const {
    query: { data, isLoading, refetch, isFetched },
  } = useGetPaymentMethods(i18n.language);
  const isAvailableGooglePay = useGooglePay();
  const isAvailableApplePay = useApplePay();

  useEffect(() => {
    if (isFetched) {
      refetch();
    }
  }, [ i18n.language ]);

  if (isAvailableGooglePay === undefined || isAvailableApplePay === undefined || isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  const getPaymentMethods = () => {
    if (!data) return [];

    const filteredData = data.filter((item) => {
      if (!isAvailableApplePay && item.name.toLowerCase().indexOf('Apple'.toLowerCase()) !== -1) return false;
      if (!isAvailableGooglePay && item.name.toLowerCase().indexOf('Google'.toLowerCase()) !== -1) return false;
      if (isRecurrent && !item.recurrent) return false;

      return true;
    });

    const sortedData = [ ...filteredData ].sort((a, b) => a.order - b.order);

    if (!isPayMethodInit) {
      const payMethod = getValues('payMethod');
      setIsPayMethodInit(true);
      !payMethod && setValue('payMethod', sortedData[0].id);
    }
    
    return sortedData;
  };

  return (
    <>
      <Typography variant="h5">{t('topup.online.choosePaymentTitle')}</Typography>
      <Typography variant="p">{t('topup.online.choosePaymentMethod')}</Typography>
      <Stack direction="column" spacing={1.5} mt={2}>
        {getPaymentMethods().map((pay) => (
          <Controller
            key={pay.name}
            name="payMethod"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Pane
                selected={value === pay.id}
                p={2}
                variant="outlined"
                onClick={(e: MouseEvent) => onChange({ ...e, target: { ...e.target, value: pay.id } })}
              >
                <Stack direction="row" spacing={2}>
                  <FormGroup>
                    <Radio
                      value={pay.name}
                      name="payMethod"
                      checked={value === pay.id}
                      onClick={(e) => onChange({ ...e, target: { ...e.target, value: pay.id } })}
                    />
                  </FormGroup>
                  <Box flexGrow={1}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent="space-between" alignItems="start">
                      <Box>
                        <Typography variant="h6">{pay.displayName}</Typography>
                        <Typography variant="p">{pay.comment}</Typography>
                      </Box>
                      <img
                        style={{ maxWidth: '100%', maxHeight: '45px' }}
                        src={'data:image/jpeg;base64,' + pay.base64Icon}
                        alt={pay.name} />
                    </Stack>
                  </Box>
                </Stack>
              </Pane>
            )}
          />
        ))}
      </Stack>
      {error && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};

export default PaymentOptions;
