import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { SIDEBAR_WIDTH } from '../constants/layout';
import { MUIStyledCommonProps } from '@mui/system';

const AppLayoutSideBarRoot = styled('nav', {
  name: 'O2AppLayout',
  slot: 'SideBar',
})(({ theme }) => ({
  width: 0,
  [theme.breakpoints.up('md')]: {
    width: SIDEBAR_WIDTH.md,
    flexShrink: 0,
  },
  [theme.breakpoints.up('xl')]: {
    width: SIDEBAR_WIDTH.xl,
  },
}));

interface AppLayoutSideBarProps extends MUIStyledCommonProps {
  children: ReactNode;
}

/**
 * @deprecated
 * @constructor
 */
const AppLayoutSideBar: FC<AppLayoutSideBarProps> = ({ children }) => {
  return <AppLayoutSideBarRoot>{children}</AppLayoutSideBarRoot>;
};

export default AppLayoutSideBar;
