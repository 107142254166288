import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import FormControlLabel from '@o2/ui/FormControlLabel';
import Checkbox from '@o2/ui/Checkbox';

import IProps from './types';

const FormCheckbox: FC<IProps> = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...rest } }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value ?? false}
              {...rest}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormCheckbox;
