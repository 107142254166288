import { useGetUserInfo } from '@hooks/useAPI';
import getMsisdnWithoutPrefix from '@utilities/formatters/getMsisdnWithoutPrefix';
import getMsisdnWithSpaces from '@utilities/formatters/getMsisdnWithSpaces';

const useAuth = () => {
  const { query: { data, isFetched} } = useGetUserInfo();

  const isLoggedIn = (): boolean => {
    return Boolean(data?.logged);
  };

  const isAnonymous = (): boolean => {
    return Boolean(data?.anonymous);
  };

  const isInternal = (): boolean => {
    return data?.internal ? data?.internal : false;
  };

  const hasRoleByName = (roleName: string): boolean => {
    return data?.roles ? data?.roles?.includes(roleName) : false;
  };

  const hasRolesByRoleNamesArray = (roleNames: string[]): boolean => {
    if (!data?.roles) {
      return false;
    }

    return roleNames.every(data?.roles.includes);
  };

  const getFullName = (): string => {
    return `${data?.firstName ? data?.firstName + ' ': ''}${data?.lastName ? data?.lastName : ''}`;
  };

  //getMsisdnWithPrefix() = +420 777555222
  //getMsisdnWithPrefix('+421', getMsisdnWithSpaces()) = +421 777 555 222
  const getMsisdnWithPrefix = (prefix = '+420', phone = '') => {
    return `${prefix} ${phone ? phone : (data?.msisdn ? data?.msisdn : '')}`;
  };

  const isOTP = () => {
    return !data?.firstName && !data?.lastName && Boolean(data?.deviceId);
  };

  return {
    isFetched,
    isLoggedIn: isLoggedIn(),
    isAnonymous: isAnonymous(),
    isInternal,
    hasRoleByName,
    hasRolesByRoleNamesArray,
    fullName: getFullName(),
    msisdnWithSpaces: getMsisdnWithSpaces(data?.msisdn ?? ''),
    getMsisdnWithPrefix,
    koId: data?.koId,
    email: data?.email,
    firstName: data?.firstName,
    lastName: data?.lastName,
    msisdn: data?.msisdn,
    deviceId: data?.deviceId,
    userDisplayName: data?.userDisplayName,
    username: data?.username,
    subscriberType: data?.subscriberType,
    isOTP: isOTP(),
    getMsisdnWithoutPrefix: (customMsisdn?: string) => getMsisdnWithoutPrefix(customMsisdn ?? data?.msisdn ?? ''),
    getDeviceIdWithoutPrefix: (customMsisdn?: string) => getMsisdnWithoutPrefix(customMsisdn ?? data?.deviceId ?? ''),
  };
};

export default useAuth;
