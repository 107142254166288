import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { O2AppUI } from '@o2/ui';
import BrowserCompatibility from '@utilities/BrowserCompatibility';

const config = {
  dateLocale: 'cs',
};

const App: FC = () => {
  return (
    <BrowserRouter>
      <O2AppUI config={config}>
        <BrowserCompatibility>
          <Router />
        </BrowserCompatibility>
      </O2AppUI>
    </BrowserRouter>
  );
};

export default App;
