import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Container, Link, Stack, Typography } from '@o2/ui';
import { Theme } from '@mui/material/styles';
import LogoBarion from '@assets/img/logo-barion.svg';
import LogoGoPay from '@assets/img/logo-gopay.svg';
import { getApiPath } from 'utilities/requests';

const Footer: FC = () => {
  const { i18n, t } = useTranslation();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleChangeLng = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'cs' : 'en');
  };

  const data = [
    { name: t('topup.footer.linkO2'), href: getApiPath('/deeplink/public/v1/deeplink?command=ETNPERSONAL') },
    { name: t('topup.footer.linkInfoTopup'), href: 'https://www.o2.cz/osobni/dobijeni' },
    { name: t('topup.footer.languageSwitch'), onClick: handleChangeLng },
  ];

  return (
    <Container maxWidth="md">
      <Stack spacing={2} direction="column">
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={2}>
          <Typography color="textSecondary" variant="caption" textAlign={mdDown ? 'center' : 'left'}>
            <Trans
              i18nKey={'topup.footer.description'}
              components={{ a: <Link href={getApiPath('/deeplink/public/v1/deeplink?command=ETNCARD')} target="_blank" /> }}
            />
          </Typography>
          <Stack direction="row" spacing={2} justifyContent={{ xs: 'center', md: 'right' }}>
            <img src={LogoBarion} alt="Barion" style={{ maxHeight: '20px' }} />
            <img src={LogoGoPay} alt="GoPay" style={{ maxHeight: '20px' }} />
          </Stack>
        </Stack>
        <Stack justifyContent="space-between" alignItems="center" direction={mdDown ? 'column' : 'row'} spacing={mdDown ? 2 : 0}>
          <Stack direction={smDown ? 'column' : 'row'} spacing={4} sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
            {data.map((item, index) => (
              <Link
                key={`${item.href} - ${index}`}
                href={item.href}
                target={item.href ? '_blank' : undefined}
                color="textSecondary"
                onClick={item.onClick}
              >
                {item.name}
              </Link>
            ))}
          </Stack>
          <Typography color="textSecondary">{t('topup.footer.copyrights')}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Footer;
