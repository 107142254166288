import { useEffect, useState } from 'react';
import useScript from './useScript';

//CONFIG GOOGLE PAY: START
const GooglePayAPI = '/pay.js';
const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedCardNetworks: [ 'VISA', 'MASTERCARD' ],
    allowedAuthMethods: [ 'PAN_ONLY', 'CRYPTOGRAM_3DS' ],
  },
};

const googlePayBaseConfiguration = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [ baseCardPaymentMethod ],
};
//CONFIG GOOGLE PAY: END

const useGooglePay = () => {
  const [ isAvailable, setIsAvailable ] = useState<boolean>();
  const { google, isLoadEnd } = useScript(GooglePayAPI, 'google');

  useEffect(() => {
    if (isLoadEnd) {
      if (google) {
        const googlePayClient = new google.payments.api.PaymentsClient({
          environment: 'TEST',
        });

        googlePayClient
          .isReadyToPay(googlePayBaseConfiguration)
          .then((res: any) => {
            if (res.result) {
              // console.log('You can pay with GooglePay.');
              setIsAvailable(true);
            } else {
              // console.log('Unable to pay using GooglePay.');
              setIsAvailable(false);
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch((err: any) => {
            // console.error('Error determining readiness to use GooglePay: ', err);
            setIsAvailable(false);
          });
      } else {
        // console.log('GooglePay API not available.');
        setIsAvailable(false);
      }
    }
  }, [ google, isLoadEnd ]);

  return isAvailable;
};

export default useGooglePay;
