/* eslint-disable max-len */
export default {
  topup: {
    header: {
      logo: 'Dobíjení',
      login: 'Přihlásit',
      userAction: {
        home: 'Moje služby',
        settings: 'Nastavení',
        logout: 'Odhlásit',
      },
    },
    online: {
      alreadyActivated: 'Na vašem čísle je již automatické dobíjení nastaveno.',
      phoneNumber: {
        label: 'Telefonní číslo',
        placeholder: 'Např. 720 222 111',
        required: 'Telefonní číslo musí být vyplněno.',
        badFormat: 'Telefonní číslo musí mít 9 číslic.',
      },
      amount: {
        placeholder: 'Zadajte vlastní částku',
        required: 'Částka musí být vyplněna.',
        hint: 'Částka v rozmezí 300 - 9 999 Kč',
        notValidNumber: 'Neplatná částka',
        outOfRange: 'Částka musí být v rozmezí 300 - 9 999 Kč.',
      },
      autoTopup: {
        showSwitcher: 'Tuto částku chci dobíjet automaticky vždy, když kredit klesne pod hodnotu, kterou si zvolím.',
        automaticAmount: {
          label: 'Dobít kredit, když se dostane pod',
          currency: 'Kč',
          placeholder: 'Zadejte hodnotu',
          hint: 'Hodnota v rozmezí 50 - 300 Kč',
          required: 'Hodnota musí být vyplněna.',
          notValidNumber: 'Neplatná hodnota',
          outOfRange: 'Hodnota musí být v rozmezí 50 - 300 Kč',
        },
        description: 'Zjednodušte si život. Když vám kredit klesne pod vámi určenou částku, automaticky se dobije. Starosti s kreditem můžete hodit za hlavu.',
      },
      submitOnce: 'Dobít',
      submitAutomatic: 'Dobíjet',
      submitContinue: 'Pokračovat',
      agreePolicy: {
        text: 'Souhlasím s <a>pravidly a podminkami</a> automatického dobíjení',
        required: 'Je vyžadován souhlas s pravidly a podminkami automatického dobíjení',
      },
      title: 'Dobití kreditu',
      description: 'Zadejte telefonní číslo a částku, kterou chcete dobít.',
      chooseAmount: 'Částka k dobití',
      customAmountToTopup: 'Vlastní částka',
      needOTPDescription: 'Abychom vám mohli nastavit automatického dobíjení při poklesu kreditu, potřebujeme v dalším kroku ověřit dobíjené telefonní číslo, na které pošleme ověřovací SMS.',
      backToMojeO2: 'Zpět do Moje O2',
      choosePaymentTitle: 'Jak chcete zaplatit?',
      choosePaymentMethod: 'Vyberte, jak chcete zaplatit za dobití kreditu.',
    },
    card: {
      online: 'Platební kartou',
      coupon: 'Kupónem',
    },
    confirmOTP: {
      title: 'Ověření telefonního čísla',
      infoText: 'Na dobíjené telefonní číslo <strong>{{phonenumber}}</strong> jsme poslali SMS s ověřovacím kódem.',
      code: {
        label: 'Ověřovací SMS kód',
        required: 'Ověřovací SMS kód musí být vyplněn.',
        wrongOTP: 'Zadejte správný ověřovací kód.',
      },
      resendOTP: 'Zaslat nový kód',
      submit: 'Potvrdit kód a dobíjet kredit',
      back: 'Zpět',
    },
    coupon: {
      title: 'Dobití kreditu',
      coupon: {
        label: 'Číslo kupónu',
        placeholder: '',
        required: 'Číslo kupónu musí být vyplněno.',
        invalidCoupon: 'Zadejte platné číslo kupónu.',
      },
      submit: 'Dobít kredit',
      description: 'Zadejte telefonní číslo a číslo kupónu, které najdete na jeho zadní straně pod stírací vrstvou.',
      phoneNumber: {
        label: 'Telefonní číslo',
        placeholder: 'Např. 720 222 111',
        required: 'Telefonní číslo musí být vyplněno.',
        badFormat: 'Telefonní číslo musí mít 9 číslic.',
      },
      link: 'Zpět do Moje O2',
    },
    success: {
      oneTimetopupTitle: 'Hotovo! Dobili jsme vám kredit za {{amount}} Kč.',
      automaticTopupTitle: 'Hotovo! Nastavili jsme vám automatické dobíjení kreditu z vaší platební karty.',
      balanceCheck: {
        text: 'Stav kreditu můžete sledovat v aplikaci',
        link: 'Moje O2.',
      },
      setAutomaticTopup: {
        text: 'Nastavení automatického dobíjení si můžete zkontrolovat v aplikaci',
        link: 'Moje O2.',
      },
      recurent: {
        phoneNumber: 'Dobíjené telefonní číslo:',
        amount: 'Dobíjená částka:',
        limit: 'Dobití kreditu při jeho poklesu pod:',
        smsSended: 'Na číslo <strong>{{phonenumber}}</strong> vám brzy přijde SMS potvrzení o dobití kreditu.',
      },
      couponApplied: 'Kupón uplatněn! Dobili jsme vám kredit za {{amount}} Kč.',
      topupAgain: 'Další dobití',
      back: 'Přejít do Moje O2',
    },
    fail: {
      title: 'Neúspěšné zpracování platby.',
      description: 'Při platbě nastala chyba, peníze vám nebyly strženy. Zkuste zaplatit znovu nebo použijte jinou kartu.',
      payAgain: 'Zaplatit znovu',
      backToTopup: 'Vrátit se na stránku dobíjení',
    },
    automaticPayment: {
      thankYou: 'Děkujeme! Nastavili jste si automatickou platbu kartou.',
      inFewMinutes: 'Provedené změny uvidíte v Moje O2 během několika minut.',
      continue: 'Zavřít',
    },
    automaticPaymentFail: {
      tryAgainLater: 'Uložení platební karty neproběhlo. Prosím, opakujte akci znovu.',
      continue: 'Pokračovat na Moje O2',
    },
    footer: {
      description: 'Platby online zpracovávají společnosti Barion a GoPay. Více informací najdete <a>zde</a>. Platební metodu Barion Peněženka poskytuje společnost Barion.',
      linkO2: 'O2.cz',
      linkInfoTopup: 'Informace o dobíjení',
      languageSwitch: 'English',
      copyrights: '© O2 Czech Republic a.s.',
    },
  },
};
