import { AlertProps as MuiAlertProps, AlertPropsColorOverrides, default as MuiAlert } from '@mui/material/Alert';
import { OverridableStringUnion } from '@mui/types';
import { forwardRef } from 'react';
import { AlertColorList } from '../theme/colorList';

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    internal: true;
  }
}

export interface AlertProps extends MuiAlertProps {
  /**
   * Color is, by default, derived from severity, but it can be overridden here.
   */
  color?: OverridableStringUnion<AlertColorList, AlertPropsColorOverrides>;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert {...props} ref={ref} />;
});

Alert.displayName = 'Alert';

export default Alert;
