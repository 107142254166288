import { REFRESH_JWT_ENDPOINT } from '@configurations/constants/api';
import axios, { AxiosRequestHeaders } from 'axios';
import i18next from 'i18next';

export const addApiBasePath = (endpoint: string): string => {
  return window.environmentConfiguration.ApiLocation + endpoint;
};

export const addCasApiBasePath = (endpoint: string): string => {
  return window.environmentConfiguration.CasLocation + endpoint;
};

export const getCasApiPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addCasApiBasePath(`${url}`);
  }
};

export const getApiPath = (url: string): string => {
  if (url.startsWith('http')) {
    return url;
  } else {
    return addApiBasePath(`/api${url}`);
  }
};

export const buildURLQuery = (parameters: object): string => {
  return (
    '?' +
    Object.entries(parameters)
      .map((parameter) => parameter.map(encodeURIComponent).join('='))
      .join('&')
  );
};

const formatLocale = (lang: string) => {
  const langs: { [key: string]: string } = {
    en: 'en-US',
    cs: 'cs-CZ',
  };

  return langs[lang];
};

const prepareHeaders = (): AxiosRequestHeaders => {
  return {
    'Content-Type': 'application/json',
    'If-None-Match': 'string',
    'Accept-Language': formatLocale(i18next.language),
  };
};

const prepareUrl = (url: string, apiBase?: string) => {
  switch (apiBase) {
    case 'cas':
      return getCasApiPath(url);
    default:
      return getApiPath(url);
  }
};

export const axiosApiInstance = axios.create({
  headers: prepareHeaders(),
  withCredentials: true,
});

axiosApiInstance.interceptors.request.use(
  (config) => {
    const { apiBase, ...rest } = config;
    config = { ...rest, url: prepareUrl(config.url ?? '', apiBase), headers: prepareHeaders() };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      await axios.get(getApiPath(REFRESH_JWT_ENDPOINT), { withCredentials: true });
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

declare module 'axios' {
  export interface AxiosRequestConfig {
    apiBase?: string
  }
}
