import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@o2/ui/Box';
import Button from '@o2/ui/Button';
import Container from '@o2/ui/Container';
import Link from '@o2/ui/Link';
import Paper from '@o2/ui/Paper';
import Typography from '@o2/ui/Typography';
import Stack from '@o2/ui/Stack';
import ReloadIcon from '@o2/icons/Reload';

import FormTextField from '@components/blocks/FormTextField';
import { useTopUpByCard } from '@hooks/useAPI';
import useCodeLists from '@hooks/useCodeLists';
import useKeyPress from '@hooks/useKeyPress';
import useAdobeAnalytics from '@hooks/useAdobeAnalytics';
import AlertAutomaticRechargeActivated from '@components/blocks/AlertAutomaticRechargeActivated/AlertAutomaticRechargeActivated';
import { getRePostQuery } from '@utilities/redirects';

interface IFormInput {
  smsVerificationCode: string
}

const ConfirmAutomaticTopUp: FC = () => {
  const keyPressed = useKeyPress('Enter');
  const navigate = useNavigate();
  const {
    mutation: { mutate: topUpByCard, isProcessing, processError },
    queryData,
  } = useTopUpByCard();
  const { t } = useTranslation();
  const { phoneNumber } = useParams();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints['down']('sm'));
  const { getItems } = useCodeLists();
  const [ otpResendCountdown ] = getItems('otpResendCountdown');
  const { pageLoaded } = useAdobeAnalytics();

  const schema = yup
    .object({
      smsVerificationCode: yup.string().required(t('topup.confirmOTP.code.required')),
    })
    .required();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      smsVerificationCode: '',
    },
    resolver: yupResolver(schema),
  });
  const [ resendStatus, setResendStatus ] = useState<number>(1);

  useEffect(() => {
    if (!queryData) navigate(`/online-topup${getRePostQuery()}`);

    pageLoaded({
      pageId: '1.3.2.1.1',
      pageTitle: 'Automatické dobití - SMS potvrzení',
    });
  }, []);

  useEffect(() => {
    if (otpResendCountdown) {
      setResendTimeout();
    }
  }, [ otpResendCountdown ]);

  useEffect(() => {
    if (keyPressed) handleSubmit(onSubmit)();
  }, [ keyPressed ]);

  useEffect(() => {
    if (processError) {
      if (processError?.response?.status === 403) {
        setError('smsVerificationCode', { type: 'custom', message: t('topup.confirmOTP.code.wrongOTP') });
      }
    }
  }, [ processError ]);

  const resendHandler = () => {
    if (!queryData) return;

    setResendStatus(1);
    topUpByCard(queryData);

    setResendTimeout();
  };

  const setResendTimeout = () => {
    setTimeout(() => {
      setResendStatus(0);
    }, otpResendCountdown * 1000);
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!queryData) return;

    topUpByCard({ ...queryData, otp: data.smsVerificationCode });
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Paper>
          <AlertAutomaticRechargeActivated />
          <Box p={{ sm: 0.5 }}>
            <Typography variant="h1" mb={3}>
              {t('topup.confirmOTP.title')}
            </Typography>
            <Typography variant="subtitle1" mb={2}>
              <Trans i18nKey="topup.confirmOTP.infoText" values={{ phonenumber: phoneNumber }} />
            </Typography>
            <Box mt={4}>
              <FormTextField
                name="smsVerificationCode"
                control={control}
                label={t('topup.confirmOTP.code.label')}
                fullWidth={smDown}
                error={Boolean(errors.smsVerificationCode)}
                errorText={errors.smsVerificationCode?.message}
              />
              <Box mt={1}>
                {resendStatus === 0 && (
                  <Link onClick={resendHandler}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <ReloadIcon sx={{ height: '16px' }} />
                      {t('topup.confirmOTP.resendOTP')}
                    </Stack>
                  </Link>
                )}
                {resendStatus === 1 && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ReloadIcon sx={{ height: '16px' }} />
                    {t('topup.confirmOTP.resendOTP')}
                  </Stack>
                )}
              </Box>
            </Box>
            <Stack mt={5} direction={{ xs: 'column', sm: 'row' }} spacing={1.5}>
              <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={!queryData || isProcessing}>
                {t('topup.confirmOTP.submit')}
              </Button>
              <Button onClick={() => navigate(`/online-topup${getRePostQuery()}`)}>{t('topup.confirmOTP.back')}</Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ConfirmAutomaticTopUp;
