import { FC, ReactElement } from 'react';
import { Link, Box, Typography, Stack } from '@o2/ui';
import { LogoFirefox, LogoGoogleChrome, ImageErrorOldBrowser } from '@o2/icons';
import ErrorLayout from '../ErrorLayout';

interface BrowserLink {
  id: number;
  label: string;
  link: string;
  icon: ReactElement;
}

const browserList: BrowserLink[] = [
  {
    id: 1,
    label: 'Stáhnout Google Chrome',
    link: 'https://www.google.com/chrome/',
    icon: <LogoGoogleChrome />,
  },
  {
    id: 2,
    label: 'Stáhnout Firefox',
    link: 'https://www.mozilla.org/cs/firefox/new/',
    icon: <LogoFirefox />,
  },
];

interface ErrorPageOldBrowserProps {
  showAppLogo?: boolean;
}

/**
 * @deprecated use ErrorPage
 */
const ErrorPageOldBrowser: FC<ErrorPageOldBrowserProps> = ({ showAppLogo = true }) => {
  return (
    <ErrorLayout image={<ImageErrorOldBrowser />} showAppLogo={showAppLogo}>
      <Typography variant="h1">
        Stránku nelze zobrazit
        <br />
        v&nbsp;tomto prohlížeči
      </Typography>
      <Typography variant="subtitle2" sx={{ mt: '20px' }}>
        Na&nbsp;O2&nbsp;webech používáme moderní technologie, se&nbsp;kterými si váš zastaralý prohlížeč neporadí.
        Pro&nbsp;přístup k&nbsp;O2 stránkám prosíme používejte některý z&nbsp;následujících moderních prohlížečů:
      </Typography>
      <Box sx={{ my: '60px' }}>
        <Stack direction="column" spacing={2}>
          {browserList.map((browser) => (
            <Stack key={browser.id} direction="row" spacing={2}>
              <Box sx={{ width: '40px', height: '40px' }}>{browser.icon}</Box>
              <Box pt="6px">
                <Link href={browser.link} target="_blank" color="white">
                  {browser.label}
                </Link>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Box>
    </ErrorLayout>
  );
};

export default ErrorPageOldBrowser;
