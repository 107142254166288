import { Container } from '@o2/common-business';
import { FC, ReactNode } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import LogoMojeO2 from '@o2/icons/LogoMojeO2';

export interface ErrorLayoutProps {
  image: ReactNode;
  showAppLogo?: boolean;
  children: ReactNode;
}

const ErrorLayout: FC<ErrorLayoutProps> = ({ children, image, showAppLogo = false }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        minHeight: showAppLogo ? '100vh' : '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {showAppLogo && (
        <Container variant="full-wide">
          <Box sx={{ mb: '2rem' }}>
            <LogoMojeO2 color="white" width="120px" />
          </Box>
        </Container>
      )}
      <Container variant="full-wide">
        <Stack
          direction={{ xs: 'column-reverse', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          sx={{ color: 'white', mb: 2 }}
          spacing={4}
        >
          <Box flexGrow={1}>{children}</Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: 'none', lg: '500px' },
              textAlign: 'center',
              px: { xs: '24%', lg: 0 },
            }}
          >
            {image}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default ErrorLayout;
