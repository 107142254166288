import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

const AppLayoutBottomRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'Bottom',
})(() => ({
  display: 'flex',
  flex: '1 1 auto',
}));

interface AppLayoutBottomProps {
  children: ReactNode;
}

/**
 * @deprecated
 * @constructor
 */
const AppLayoutBottom: FC<AppLayoutBottomProps> = ({ children }) => {
  return <AppLayoutBottomRoot>{children}</AppLayoutBottomRoot>;
};

export default AppLayoutBottom;
