import { initScripts } from '@o2/ui';
import getLanguageInISO from '@utilities/formatters/getLanguageInISO';
import i18n from '@utilities/i18n';
import { useEffect } from 'react';
import useAuth from './useAuth';
import { getAdobeDataLayer } from '@o2/ui/O2Script';
import packageJson from '../../package.json';

const useAdobeAnalytics = () => {
  const { username, userDisplayName, msisdn, isLoggedIn } = useAuth();
  const { trackPageLoaded } = getAdobeDataLayer();

  useEffect(() => {
    if ((window as any).adobeDataLayer) {
      (isLoggedIn && (window as any).adobeDataLayer.push({
        user: {
          userType: 'external',
          userName: username,
          msisdn: msisdn,
          customerName: userDisplayName,
        },
      }));
    }
  }, [ (window as any).adobeDataLayer ]);

  const pageLoaded = (
    params: {
      pageId: string
      pageTitle: string
    }
  ) => {
    if ((window as any).adobeDataLayer) {
      (window as any).adobeDataLayer.push({
        module: 'Recharging',
        moduleVersion: packageJson.version,
      });
      trackPageLoaded({...params, language: getLanguageInISO(i18n.language)});
    }
  };

  const init = () => {
    initScripts(window.environmentConfiguration.AdobeLunchPatch);
  };

  const formFirstInteraction = (
    formType?: string
  ) => {
    if ((window as any).adobeDataLayer) {
      (window as any).adobeDataLayer.push({
        event: 'formFirstInteraction',
        formId: 'O2 Dobíjení|recharging-form',
        formType,
      });
    }
  };

  const formSubmit = (
    phoneNumber: string,
    topupAmount: number,
    paymentMethod: number,
    topupMonthly: boolean,
    topupUnderLimit: boolean,
    topupUnderLimitAmount: number | undefined,
    formTypeCoupon: boolean,
  ) => {
    if ((window as any).adobeDataLayer) {
      (window as any).adobeDataLayer.push({
        event: 'formSubmit',
        formId: 'O2 Dobíjení|recharging-form',
        formType: (formTypeCoupon ? 'coupon' : 'payment'),
        msisdn: '420' + phoneNumber,
        topupAmount,
        paymentMethod,
        topupMonthly,
        topupUnderLimit,
        topupUnderLimitAmount,
      });
    }
  };

  const rechargingPaymentGatewayResult = (
    resultSucess: boolean,
    transactionId: number,
    phoneNumber: string,
    topupAmount: number,
    paymentMethod: number,
    topupMonthly: boolean | undefined,
    topupUnderLimit: boolean,
    topupUnderLimitAmount: number | undefined,
    formTypeCoupon: boolean,
  ) => {
    if ((window as any).adobeDataLayer) {
      (window as any).adobeDataLayer.push({
        event: 'paymentResult',
        result: (resultSucess ? 'success' : 'failed'),
        transactionId,
        formId: 'O2 Dobíjení|recharging-form',
        formType: (formTypeCoupon ? 'coupon' : 'payment'),
        msisdn: '420' + phoneNumber,
        topupAmount,
        paymentMethod,
        topupMonthly,
        topupUnderLimit,
        topupUnderLimitAmount,
      });
    }
  };

  return {
    init,
    pageLoaded,
    formFirstInteraction,
    formSubmit,
    rechargingPaymentGatewayResult,
  };

};

export default useAdobeAnalytics;
