import { useQuery, useQueries, QueryClient } from 'react-query';
import queryClient from '@state/reactQuery/queryClient';
import { axiosApiInstance } from '@utilities/requests';
import useMutation from './useMutation';

import kahl from '@configurations/constants/kahl';
import { CODE_LIST_API_BASE, AUTH_API_BASE, KAHL_API_BASE } from '@configurations/constants/api';
import {
  IGetCpRelationsResponse,
  IPaymentMethod,
  ITopupByCouponResponse,
  ITopupByCouponParams,
  ITopupByCardParams,
  ITopupByCardResponse,
  IUserInfo,
  IKahlMessage,
  IKahlQueryParams,
} from '@configurations/types';

export function useTopUpByCard(customQueryClient?: QueryClient) {
  return {
    mutation: useMutation<ITopupByCardResponse, any, ITopupByCardParams>(
      (params: ITopupByCardParams) => axiosApiInstance.post('/topup/public/v1/topupByCard', undefined, { params }),
      {
        onError: (error: any, variables: ITopupByCardParams) => {
          if (error?.response?.data?.url) window.location.href = error.response.data.url;
          queryClient.setQueryData('topUpByCard', variables);
          queryClient.setQueryData('topUpByCardResponse', error?.response?.data);
          if (customQueryClient) customQueryClient.setQueryData('topUpByCard', variables);
        },
        onSuccess: (data: ITopupByCardResponse, variables: ITopupByCardParams) => {
          queryClient.setQueryData('topUpByCard', variables);
          queryClient.setQueryData('topUpByCardResponse', data);
          if (data.url) window.location.href = data.url;
        },
      }
    ),
    query: undefined,
    queryData: queryClient.getQueryData<ITopupByCardParams>('topUpByCard'),
    queryResponse: queryClient.getQueryData<ITopupByCardResponse>('topUpByCardResponse'),
  };
}

export function useTopUpByCoupon(callback?: () => void) {
  return {
    mutation: useMutation<ITopupByCouponResponse, any, ITopupByCouponParams>(
      (params: ITopupByCouponParams) => axiosApiInstance.post('/topup/public/v1/topUpByCoupon', undefined, { params }),
      {
        onError: (_error: any, variables: ITopupByCouponParams) => {
          queryClient.setQueryData('topUpByCoupon', variables);
        },
        onSuccess: (data: ITopupByCouponResponse, variables: ITopupByCouponParams) => {
          queryClient.setQueryData('topUpByCoupon', variables);
          queryClient.setQueryData('topUpByCouponResponse', data);
          queryClient.setQueryData('kahlIdStore', { kahlMessageId: kahl.topupCouponSuccess, timestamp: Date.now() });
          callback?.();
        },
      }
    ),
    query: undefined,
    queryData: queryClient.getQueryData<ITopupByCouponParams>('topUpByCoupon'),
  };
}

export function useGetPaymentMethods(language: string) {
  return {
    mutation: undefined,
    query: useQuery<unknown, unknown, IPaymentMethod[]>('getPaymentMethods', () =>
      axiosApiInstance.get('/topup/public/v1/getPaymentMethods', { params: { language } })
    ),
    queryData: undefined,
  };
}

export function useGetCpRelations(isLoggedIn: boolean) {
  return {
    mutation: undefined,
    query: useQuery<unknown, unknown, IGetCpRelationsResponse>(
      'getCpRelations',
      () => axiosApiInstance.get('/topup/public/v1/getCpRelations'),
      { enabled: isLoggedIn }
    ),
    queryData: undefined,
  };
}

export function useGetCodeList(codeListNames: string[]) {
  const queries = codeListNames.map((codeList, index) => {
    return {
      queryKey: [ codeList, index + 1 ],
      queryFn: () => axiosApiInstance.get(`${CODE_LIST_API_BASE}/${codeList}`),
    };
  });

  return {
    mutation: undefined,
    query: useQueries(queries),
    queryData: undefined,
  };
}

export function useGetUserInfo() {
  return {
    mutation: undefined,
    query: useQuery<unknown, unknown, IUserInfo>('getUserInfo', () => axiosApiInstance.get(`${AUTH_API_BASE}/userInfo`)),
    queryData: undefined,
  };
}

export function useGetKahlMessage() {
  return {
    mutation: useMutation<IKahlMessage[], any, IKahlQueryParams>(
      (params: IKahlQueryParams) => axiosApiInstance.get(`${KAHL_API_BASE}`, { params }),
      {
        onSuccess: (data: IKahlMessage[]) => {
          queryClient.setQueryData('kahlMessage', { data, timestamp: Date.now() });
        },
      }
    ),
    query: undefined,
    queryData: undefined,
  };
}
