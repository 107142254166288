import { LOGIN_URL, KAHL_API_BASE } from '@configurations/constants/api';

import { getApiPath, buildURLQuery } from '../requests';

export const redirectToLoginWithSettings = (settings: object, apiLocation = LOGIN_URL): void => {
  window.location.href = getApiPath(apiLocation + buildURLQuery(settings));
};

export const redirectToLoginPageCheckOnly = (
  authLocation = LOGIN_URL,
): void => {
  const loginSetting = {
    redirectTo: window.location.href,
    checkOnly: 'true',
    internalLogin: 'false',
  };

  redirectToLoginWithSettings(loginSetting, authLocation);
};

export const redirectToLoginPageExternalForced = (
  authLocation = LOGIN_URL,
): void => {
  const loginSetting = {
    redirectTo: window.location.href,
    internalLogin: 'false',
    forceLogin: 'true',
  };

  redirectToLoginWithSettings(loginSetting, authLocation);
};

export const redirectToStandaloneKahl = (id: string, appId: string) => {
  window.location.href = getApiPath(`${KAHL_API_BASE}/standalone-message/?appId=${appId}&msgId=${id}`);
};

export const getRePostQuery = () => {
  const webview = window.localStorage.getItem('webview');
  if (webview) return `?webview=${webview}`;

  return '';
};
