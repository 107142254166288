import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '../Stack';
import Box from '../Box';

const MultipleSnackbarsRoot = styled(Box, {
  name: 'O2MultipleSnackbars',
  slot: 'Root',
})(({ theme }) => ({
  position: 'fixed',
  left: theme.spacing(1),
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    left: theme.spacing(3),
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  maxWidth: 600,
  zIndex: theme.zIndex.snackbar,
  '& .MuiSnackbar-root': {
    position: 'relative',
    left: 'auto',
    bottom: 'auto',
    right: 'auto',
  },
}));

export interface MultipleSnackbarsProps {
  children: ReactNode;
}

const MultipleSnackbars: FC<MultipleSnackbarsProps> = ({ children, ...other }) => {
  return (
    <MultipleSnackbarsRoot {...other}>
      <Stack spacing={2} direction="column-reverse">
        {children}
      </Stack>
    </MultipleSnackbarsRoot>
  );
};

export default MultipleSnackbars;
