// Left bar fixed width (i.e. Drawer)
const SIDEBAR_WIDTH = {
  xs: 240,
  md: 120,
  xl: 240,
};

// External AppBar height
const APPBAR_EXT_HEIGHT = {
  xs: 48,
  md: 64,
};
// Internal AppBar height
// Info: Original appbar height 32 px
const APPBAR_INT_HEIGHT = 48;

// Header height
// We won't use it after all.
//const HEADER_HEIGHT = 95;

// Left page-specific navigation width
const PAGE_NAVIGATION_WIDTH = 238;

export { SIDEBAR_WIDTH, APPBAR_EXT_HEIGHT, APPBAR_INT_HEIGHT, PAGE_NAVIGATION_WIDTH };
