/* eslint-disable max-len */
import { useState, useEffect } from 'react';

import { useGetCodeList } from '@hooks/useAPI';
import { DEPENDENT_CODE_LIST } from '@configurations/constants/codeLists';

const useCodeLists = () => {
  const [ areCodelistsFetched, setCodelistsFetched ] = useState(false);
  const { query } = useGetCodeList(DEPENDENT_CODE_LIST);

  useEffect(() => {
    if (!query.some((item) => item.isLoading)) {
      setCodelistsFetched(true);
    }
  }, [ query ]);

  const getItems = (...keys: string[]) => {
    // if codelists are available
    if (!query.some((codeList) => codeList.data)) {
      return [];
    }

    let mergedConddLists = {};
    const codeListValue: any[] = [];
    query.forEach((codeList) => mergedConddLists = { ...mergedConddLists, ...codeList.data});

    keys.map((key) => {
      const value = mergedConddLists[key as keyof typeof mergedConddLists];
      if (value) codeListValue.push(value);
    });
    return codeListValue;
  };

  return { areCodelistsFetched, getItems };
};

export default useCodeLists;
