import { useEffect, useState } from 'react';

import useCodeLists from '@hooks/useCodeLists';
import useAuth from '@hooks/useAuth';

import { redirectToLoginPageCheckOnly } from '@utilities/redirects';
import useAdobeAnalytics from './useAdobeAnalytics';

const useAppInit = () => {
  const [ isReadyToRender, setIsReadyToRender ] = useState<boolean>(false);
  const { areCodelistsFetched } = useCodeLists();
  const { isFetched, isLoggedIn, isAnonymous } = useAuth();
  const { init } = useAdobeAnalytics();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (areCodelistsFetched) {
      setIsReadyToRender(true);
    }
  }, [ areCodelistsFetched ]);

  useEffect(() => {
    if (isFetched && !isLoggedIn && !isAnonymous) {
      redirectToLoginPageCheckOnly();
    }
  }, [ isFetched ]);

  return {
    isReadyToRender,
  };
};

export default useAppInit;
