import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import csTranslation from '@configurations/translations/cs';
import enTranslation from '@configurations/translations/en';
import { CMS_RESOURCE_API_BASE } from '@configurations/constants/api';
import { APP_ID_LC } from '@configurations/constants';

import { getApiPath } from 'utilities/requests';
import { axiosApiInstance } from './requests';
import flattenObject from './formatters/flattenObject';

const resources = {
  cs: { translation: csTranslation },
  en: { translation: enTranslation },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: window.localStorage.i18nextLng ?? 'cs',
    supportedLngs: [ 'cs', 'en' ],
    preload: [ 'cs', 'en' ],
    interpolation: {
      escapeValue: false,
    },
    ns: [ APP_ID_LC ],
    load: 'languageOnly',
    backend: {
      loadPath: getApiPath(`${CMS_RESOURCE_API_BASE}/LANGTEXT/{{ns}}`),
      parse: (data: string, languages?: string | string[], namespaces?: string | string[]) => {
        if (typeof namespaces === 'string' && typeof languages === 'string' && data !== 'Error') {
          const translations = JSON.parse(data);
          const localTranslations = flattenObject(resources[languages as keyof typeof resources]?.translation);

          return { ...localTranslations, ...translations?.[namespaces]?.['<ANY>']?.[languages] };
        }

        return resources[languages as keyof typeof resources]?.translation;
      },
      request: async (options, url, payload, callback) => {
        try {
          const data = await axiosApiInstance.get(url);
          callback(null, {
            data: JSON.stringify(data),
            status: 200,
          });
        } catch (e) {
          callback(null, {
            data: 'Error',
            status: 200,
          });
        }
      },
      reloadInterval: false,
    },
  });

export default i18n;
