import { FC } from 'react';
import Box, { BoxProps } from '@o2/ui/Box';
import { ClassNameMap, styled, unstable_composeClasses as composeClasses, useTheme } from '@mui/material';
import { getToolbarUtilityClass } from './mojeO2MobileAppBannerClasses';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@o2/ui/Stack';

import MojeO2AppBannerDesktopLink from '@o2/icons/assets/images/MojeO2AppBannerDesktopLink';
import MojeO2AppBannerMobileLink from '@o2/icons/assets/images/MojeO2AppBannerMobileLink';
import QrCodeMojeO2MobileApp from '@o2/icons/QrCodeMojeO2MobileApp';
import GooglePlayButton from '../Buttons/GooglePlayButton';
import AppStoreButton from '../Buttons/AppStoreButton';

const BannerBox = styled(Box, {
  name: 'O2MobileAppBanner',
  slot: 'Root',
})<MojeO2MobileAppBannerPropsWithOwnerState>(({ theme, maxWidth }) => ({
  backgroundColor: '#DCF2FF',
  borderRadius: '8px',
  padding: 0,
  margin: 0,
  border: '1px solid rgba(0,0,0,0.15)',
  maxWidth: maxWidth ?? 'auto',
  overflow: 'hidden',
  '& .O2MobileAppBanner-leftContainer': {
    padding: '16px',
  },
  '& .O2MobileAppBanner-leftContainerStack': {
    height: '100%',
  },
  '& .O2MobileAppBanner-appButtonStack': {
    flexDirection: 'row',
    gap: '8px',
    ['@media only screen and (max-width: 340px)']: {
      flexDirection: 'column',
    },
  },
  '& .O2MobileAppBanner-rightContainer': {
    width: '445px',
    maxWidth: '100%',
    backgroundImage: `url(${MojeO2AppBannerDesktopLink})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: 'auto 100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundSize: 'contain',
      backgroundImage: `url(${MojeO2AppBannerMobileLink})`,
      backgroundPosition: 'bottom center',
      height: '62vw',
      maxHeight: '300px',
    },
  },
  '& .O2MobileAppBanner-rightContainerBox': {
    display: 'flex',
    justifyContent: 'left',
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '274px',
    height: '100%',
  },
  '& .O2MobileAppBanner-qrcode': {
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    width: '104px',
    height: '104px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
}));

type MojeO2MobileAppBannerOwnerState = {
  classes?: ClassNameMap<never>;
  maxWidth?: number | string;
  LeftContainerProps?: BoxProps;
  RightContainerProps?: BoxProps;
};

export interface MojeO2MobileAppBannerProps extends BoxProps {
  maxWidth?: string | number;
  className?: string;
  LeftContainerProps?: BoxProps;
  RightContainerProps?: BoxProps;
  showQrCode?: boolean;
  showButtons?: boolean;
}

interface MojeO2MobileAppBannerPropsWithOwnerState extends MojeO2MobileAppBannerProps {
  ownerState: MojeO2MobileAppBannerOwnerState;
}

const useUtilityClasses = (ownerState: MojeO2MobileAppBannerOwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['root'],
    leftContainer: ['leftContainer'],
    leftContainerStack: ['leftContainerStack'],
    appButtonStack: ['appButtonStack'],
    rightContainer: ['rightContainer'],
    rightContainerBox: ['rightContainerBox'],
    image: ['image'],
    qrCode: ['qrcode'],
  };

  const composedClasses = composeClasses(slots, getToolbarUtilityClass, classes);

  return {
    ...classes,
    ...composedClasses,
  };
};

const MojeO2MobileAppBanner: FC<MojeO2MobileAppBannerProps> = (props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    children,
    className,
    LeftContainerProps,
    RightContainerProps,
    showQrCode = true,
    showButtons = true,
    ...other
  } = props;

  const classes = useUtilityClasses(props);

  return (
    <BannerBox ownerState={props} className={clsx(classes.root, className)} {...other}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Box flexGrow={1} className={classes.leftContainer} {...LeftContainerProps}>
          <Stack className={classes.leftContainerStack} direction="column">
            <Box flexGrow={1}>{children}</Box>
            {showButtons && (
              <Box>
                <Stack className={classes.appButtonStack}>
                  <AppStoreButton
                    onClick={() => {
                      window.open('https://apps.apple.com/cz/app/moje-o2/id564164409', '_blank')?.focus();
                    }}
                  />
                  <GooglePlayButton
                    onClick={() => {
                      window.open('https://play.google.com/store/apps/details?id=cz.o2.moje', '_blank')?.focus();
                    }}
                  />
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
        <Box className={classes.rightContainer} {...RightContainerProps}>
          <Box className={classes.rightContainerBox}>
            {showQrCode && (
              <Box hidden={smDown} className={classes.qrCode}>
                <QrCodeMojeO2MobileApp />
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
    </BannerBox>
  );
};

export default MojeO2MobileAppBanner;
