import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Box, Button, Container, Pane, Stack, Typography } from '@o2/ui';
import StatusErrorSmall from '@o2/icons/StatusErrorSmall';
import StatusError from '@o2/icons/StatusError';
import { Theme } from '@mui/material/styles';

import queryClient from '@state/reactQuery/queryClient';
import kahl from '@configurations/constants/kahl';
import { useTopUpByCard } from '@hooks/useAPI';
import useAdobeAnalytics from '@hooks/useAdobeAnalytics';
import { ITopupByCardParams } from '@configurations/types';
import { getRePostQuery } from '@utilities/redirects';

const TopUpFail: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    mutation: { mutate: topUpByCard, isProcessing },
    queryData,
    queryResponse,
  } = useTopUpByCard();
  const { rechargingPaymentGatewayResult, pageLoaded } = useAdobeAnalytics();

  useEffect(() => {
    if (queryData && queryResponse) {
      queryClient.setQueryData('kahlIdStore', { kahlMessageId: kahl.returnPaymentGatewayFail, timestamp: Date.now() });
    }
  }, []);

  useEffect(() => {
    pageLoaded({
      pageId: '1.3.2.1.4',
      pageTitle: 'Potvrzení negativní',
    });
    if (queryResponse && queryData) {
      rechargingPaymentGatewayResult(
        false,
        queryResponse.paymentId,
        queryData.msisdn,
        queryData.amount,
        queryData.paymentMethod,
        !!queryData.rechargeEveryMonth,
        !!queryData.rechargeIfLow,
        queryData.rechargeIfLowValue,
        false
      );
    }
  }, [ queryResponse, queryData ]);

  const payAgain = () => {
    if (!queryData) return;

    topUpByCard(queryData, {
      onSettled: (_data: any, error: any, { msisdn }: ITopupByCardParams) => {
        if (error?.response?.status === 412) navigate(`/confirm-automatic-topup/${msisdn}${getRePostQuery()}`);
      },
    });
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box mb={3}>
          <Pane info="error" p={2}>
            <Stack direction="row" spacing={2}>
              <Box>{smDown ? <StatusErrorSmall /> : <StatusError />}</Box>
              <Box>
                <Typography variant="h5" mb={2}>
                  {t('topup.fail.title')}
                </Typography>
                <Typography>{t('topup.fail.description')}</Typography>
                <Box mt={2}>
                  <Button onClick={payAgain} disabled={!queryData || isProcessing} variant="contained" fullWidth={smDown}>
                    {t('topup.fail.payAgain')}
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Pane>
        </Box>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">
          <Button
            onClick={() => navigate(`/online-topup${getRePostQuery()}`, { state: { preFillForm: true } })}
          >
            {t('topup.fail.backToTopup')}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default TopUpFail;
