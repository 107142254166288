import React, { FC, ReactElement } from 'react';
import ErrorPageOldBrowser from '@o2/common-pages/ErrorPages/ErrorPageOldBrowser';
import supportedBrowsers from './supportedBrowsers';

interface BrowserCompatibilityProps {
  children: ReactElement
}

const BrowserCompatibility: FC<BrowserCompatibilityProps> = ({ children }) => {
  if (!supportedBrowsers.test(navigator.userAgent)) {
    return <ErrorPageOldBrowser />;
  }

  return <>{children}</>;
};

export default BrowserCompatibility;
