import { FC, MouseEventHandler } from 'react';
import { styled } from '@mui/material';
import LogoAppStore from '@o2/icons/LogoAppStore';

interface AppStoreButton {
  onClick: MouseEventHandler;
}

const Button = styled('div', {
  name: 'AppStoreButton',
  slot: 'Root',
})(() => ({
  height: '40px',
  minWidth: '135px',
  cursor: 'pointer',
}));

const AppStoreButton: FC<AppStoreButton> = (props) => {
  return (
    <Button {...props}>
      <LogoAppStore />
    </Button>
  );
};

export default AppStoreButton;
