import { QueryClient, QueryCache, MutationCache } from 'react-query';
import { PersistedClient, persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';

import kahl from '@configurations/constants/kahl';

const PERSIST_WHITELIST = [ 'topUpByCard', 'topUpByCardResponse', 'topUpByCoupon', 'topUpByCouponResponse' ];

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: (response: any) => processResultCode(response?.resultCode),
    onError: (error: any) => processResultCode(error?.response?.data?.resultCode),
  }),
  mutationCache: new MutationCache({
    onSuccess: (response: any) => processResultCode(response?.resultCode),
    onError: (error: any) => processResultCode(error?.response?.data?.resultCode),
  }),
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60, // 1 hour
      queryFn: () => null,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

const processResultCode = (resultCode?: string) => {
  const kahlMessageId = kahl[resultCode as keyof typeof kahl];
  kahlMessageId && queryClient.setQueryData('kahlIdStore', { kahlMessageId, timestamp: Date.now() });
};

const saveByWhitelist = (client: PersistedClient) => {
  const clientCopy: PersistedClient = JSON.parse(JSON.stringify(client));
  clientCopy.clientState.queries = clientCopy.clientState.queries.filter((query) => PERSIST_WHITELIST.includes(`${query.queryKey}`));

  return JSON.stringify(clientCopy);
};

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  serialize: saveByWhitelist,
  throttleTime: 0,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

export default queryClient;
