import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

/**
 * !!! Deprecated !!!
 * Basic app layout styles. The structure is as follows:
 * - Root
 *   - Top
 *     - Internal bar (optional)
 *     - App bar
 *   - Bottom
 *     - SideBar
 *     - Page
 *       - Header (optional)
 *       - Content
 *         - Content navig (optionl)
 *         - Main content
 *
 */
const AppLayoutRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'Root',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'stretch',
}));

interface AppLayoutProps {
  children: ReactNode;
}

/**
 * @deprecated
 * @constructor
 */
const AppLayout: FC<AppLayoutProps> = (props) => {
  return <AppLayoutRoot>{props.children}</AppLayoutRoot>;
};

export default AppLayout;
