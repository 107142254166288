import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
import '@utilities/i18n';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import queryClient from '@state/reactQuery/queryClient';
import { RequestMockFactory } from 'mock-factory/types';
import axios from 'axios';
import useDevOpsDebug from '@hooks/useDevOpsDebug';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
const { logDevOpsVariables } = useDevOpsDebug();

const renderIt = () => {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  );
};

//============ MOCK FACTORY START PLUGIN ADD
const myStorage = window.localStorage;
const mockFactoryAppName = myStorage.getItem('mock-factory-app');
const mockFactorySet = myStorage.getItem('mock-factory-set');

if (mockFactoryAppName && mockFactorySet) {
  const MOCK_FACTORY_EP = 'https://mock-factory.herokuapp.com/public/getSet';

  axios.get(`${MOCK_FACTORY_EP}/${mockFactoryAppName}/${mockFactorySet}`)
    .then((response: { data: RequestMockFactory[]}) => {
      import('./mock-factory/index')
        .then((mockFactory) => {
          mockFactory.mockFactoryInitByAppNameAndSetName(response.data);
          console.log('%cMock Factory is Running!! ','color: #dede2c; font-size: 24px; background: #383838;');
          renderIt();
        });
    }).catch((error) => {
      console.error(
        'Mock factory: ' + error.response.data.message + ' Please check your Local Storage variables mock-factory-app and mock-factory-set.'
      );
    });
} else {
  renderIt();
}
//============ MOCK FACTORY END PLUGIN ADD

logDevOpsVariables();
reportWebVitals();
