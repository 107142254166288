import { FC, ReactNode } from 'react';
import AppLayoutContentNavigation from '../AppLayout/AppLayoutContentNavigation';
import AppLayoutContentMain from './AppLayoutContentMain';
import { styled } from '@mui/material/styles';

interface AppLayoutContentProps {
  children: ReactNode;
  navigation?: ReactNode;
}

const AppLayoutContentRoot = styled('main', {
  name: 'O2AppLayout',
  slot: 'Content',
})(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  [theme.breakpoints.between('xs', 'md')]: {
    flexDirection: 'column',
    // no paddings and margins for layouts, will be handled in page template via p={{ xs: 2, lg: 4 }} approach
    // marginRight: '18px',
    // marginLeft: '18px',
  },
}));

/**
 * @deprecated
 * @constructor
 */
const AppLayoutContent: FC<AppLayoutContentProps> = (props) => {
  const { navigation, children } = props;

  return (
    <AppLayoutContentRoot>
      {navigation && <AppLayoutContentNavigation>{navigation}</AppLayoutContentNavigation>}
      <AppLayoutContentMain>{children}</AppLayoutContentMain>
    </AppLayoutContentRoot>
  );
};

export default AppLayoutContent;
