import React, { FC } from 'react';
import { Props } from './types';
import { Outlet } from 'react-router-dom';
import RootLayout from '@components/layouts/RootLayout';
import Layout from '@o2/ui/AppLayout';
import CircularProgress from '@o2/ui/CircularProgress';
import Stack from '@o2/ui/Stack';
import { AppLayoutTop, AppLayoutBottom, AppLayoutPage, AppLayoutContentFooter } from '@o2/ui/AppLayout';
// import AppBar from './O2AppBar';
// import Footer from './Footer';

import useAppInit from '@hooks/useAppInit';
import Header from '@components/sections/Header';
import Footer from '@components/sections/Footer';

const AppLayout: FC<Props> = () => {
  const webview = window.localStorage.getItem('webview');
  const { isReadyToRender } = useAppInit();

  if (!isReadyToRender) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <RootLayout>
      <Layout>
        {!webview && (
          <AppLayoutTop hasInternalBar={false}>
            <Header />
          </AppLayoutTop>
        )}
        <AppLayoutBottom>
          <AppLayoutPage>
            <Outlet />
          </AppLayoutPage>
        </AppLayoutBottom>
        {!webview && (
          <AppLayoutContentFooter px={4} py={2}>
            <Footer />
          </AppLayoutContentFooter>
        )}
      </Layout>
    </RootLayout>
  );
};

export default AppLayout;
