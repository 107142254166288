export default {
  returnPaymentGatewaySuccess: 'ID0955',
  returnPaymentGatewayFail: 'ID0957',
  '-1': 'ID0959',
  '-2': 'ID0960',
  '-3': 'ID0961',
  '-4': 'ID0962',
  '-5': 'ID0963',
  '-6': 'ID0964',
  '-7': 'ID0965',
  '-8': 'ID0966',
  '-9': 'ID0967',
  '-10': 'ID1085',
  '-11': 'ID1086',
  '-13': 'ID1087',
  '-20': 'ID0968',
  '-22': 'ID1649',
  '-23': 'ID1649',
  topupCouponSuccess: 'ID0969',
  1: 'ID0971',
  4: 'ID0972',
  5: 'ID0973',
  7: 'ID0974',
  10: 'ID0975',
  12: 'ID0976',
  14: 'ID0977',
  16: 'ID0978',
  30: 'ID0979',
  98: 'ID0980',
  99: 'ID0981',
};
