import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import AppLayout from '@components/layouts/AppLayout';
import OnlineTopUp from '@pages/OnlineTopUp';
import ConfirmAutomaticTopUp from '@pages/ConfirmAutomaticTopUp';
import TopUpSuccess from '@pages/TopUpSuccess';
import TopUpFail from '@pages/TopUpFail';
import ConfirmAutomaticPayment from '@pages/ConfirmAutomaticPayment/ConfirmAutomaticPayment';
import ConfirmAutomaticPaymentFail from '@pages/ConfirmAutomaticPaymentFail/ConfirmAutomaticPaymentFail';

const Router = () => {
  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const webview = searchParams.get('webview');

    if (webview) {
      window.localStorage.setItem('webview', 'true');
    }
  }, [ ]);

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Navigate to="online-topup" replace />} />
        <Route path="online-topup" element={<OnlineTopUp />}>
          <Route path="coupon" element={<OnlineTopUp />} />
        </Route>
        <Route path="confirm-automatic-topup" element={<ConfirmAutomaticTopUp />}>
          <Route path=":phoneNumber" element={<ConfirmAutomaticTopUp />} />
        </Route>
        <Route path="dobijeni">
          <Route path="success" element={<TopUpSuccess />} />
          <Route path="fail" element={<TopUpFail />} />
        </Route>
        <Route path="automaticka-platba">
          <Route path="success" element={<ConfirmAutomaticPayment />} />
          <Route path="fail" element={<ConfirmAutomaticPaymentFail />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="online-topup" replace />} />
    </Routes>
  );
};

export default Router;
