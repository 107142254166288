import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { APPBAR_EXT_HEIGHT, APPBAR_INT_HEIGHT } from '../constants/layout';

interface AppLayoutTopProps {
  children: ReactNode;
  hasInternalBar: boolean;
}

interface AppLayoutTopRootProps {
  hasInternalBar?: boolean;
}

const AppLayoutTopRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'Top',
  shouldForwardProp: (prop) => prop !== 'hasInternalBar' && prop !== 'isInternalBarVisible',
})<AppLayoutTopRootProps>(({ theme, hasInternalBar }) => ({
  zIndex: theme.zIndex.drawer + 1,
  flex: `0 0 ${hasInternalBar ? 2 * APPBAR_INT_HEIGHT + APPBAR_EXT_HEIGHT.xs : APPBAR_EXT_HEIGHT.xs}px`,
  [theme.breakpoints.up('sm')]: {
    flex: `0 0 ${hasInternalBar ? 2 * APPBAR_INT_HEIGHT + APPBAR_EXT_HEIGHT.xs : APPBAR_EXT_HEIGHT.xs}px`,
  },
  [theme.breakpoints.up('md')]: {
    flex: `0 0 ${hasInternalBar ? APPBAR_INT_HEIGHT + APPBAR_EXT_HEIGHT.md : APPBAR_EXT_HEIGHT.md}px`,
  },
}));

const AppLayoutTopHeader = styled('div', {
  name: 'O2AppLayout',
  slot: 'TopHeader',
})(({ theme }) => ({
  minHeight: APPBAR_EXT_HEIGHT.xs,
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_EXT_HEIGHT.md,
  },
}));

/**
 * @deprecated
 * @constructor
 */
const AppLayoutTop: FC<AppLayoutTopProps> = (props) => {
  const { hasInternalBar, children } = props;

  return (
    <AppLayoutTopRoot hasInternalBar={hasInternalBar}>
      <AppLayoutTopHeader>{children}</AppLayoutTopHeader>
    </AppLayoutTopRoot>
  );
};

export default AppLayoutTop;
