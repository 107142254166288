import packageJson from '../../package.json';

const useDevOpsDebug = () => {
  const devOpsDebug = window.localStorage.getItem('devOpsDebug');

  const logDevOpsVariables = () => {
    if (devOpsDebug) {
      console.log('RELEASE VERSION TAG: ' + packageJson.version);
      console.log('ENVIRONMENT VARIABLES:');
      console.table(window.environmentConfiguration);
    }
  };

  return { logDevOpsVariables };
};

export default useDevOpsDebug;
