import React, { FC, useState, useEffect } from 'react';

import Snackbar from '@o2/ui/Snackbar';
import MultipleSnackbars from '@o2/ui/MultipleSnackbars';
import Alert from '@o2/ui/Alert';

import IProps, { IFlashMessage } from './types';
import { newId } from './util';

const FlashMessage: FC<IProps> = ({ message }) => {
  const [ messages, setMessages ] = useState([]);

  const handleClose = (id: string) => {
    setMessages((prevState) => {
      return [ ...prevState.filter((msg: IFlashMessage) => msg.id !== id) ];
    });
  };

  const addMessage = (newMessage: IFlashMessage) => {
    if (newMessage.autoClose !== 0) {
      setTimeout(() => {
        handleClose(newMessage.id);
      }, newMessage.autoClose * 1000);
    }

    setMessages((prevState) => {
      const newMessages: any = [ ...prevState ];
      newMessages.push(newMessage);
      return newMessages;
    });
  };

  useEffect(() => {
    if (message !== null) {
      addMessage({ ...message, id: newId() });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      <MultipleSnackbars>
        {messages &&
          messages.map((msg: IFlashMessage) => (
            <Snackbar key={msg.id} open onClose={() => handleClose(msg.id)}>
              <Alert color={msg.color}>
                <div dangerouslySetInnerHTML={{ __html: msg.text }} />
              </Alert>
            </Snackbar>
          ))}
      </MultipleSnackbars>
    </>
  );
};

export default FlashMessage;
