import { generateUtilityClass, generateUtilityClasses, unstable_composeClasses as composeClasses } from '@mui/material';

export function getTextField2UtilityClass(slot: string) {
  return generateUtilityClass('O2TextField', slot);
}

const textField2Classes = generateUtilityClasses('O2TextField', [
  'root',
  'labelContainer',
  'label',
  'labelNote',
  'input',
  'select',
  'placeholder',
  'helperText',
  'errorText',
  'menuList',
  'menuPaper',
]);

export default textField2Classes;

export const useTextField2Classes = (classes?: Record<string, string>) => {
  return composeClasses(
    {
      root: ['root'],
      labelContainer: ['labelContainer'],
      label: ['label'],
      labelNote: ['labelNote'],
      input: ['input'],
      select: ['select'],
      placeholder: ['placeholder'],
      helperText: ['helperText'],
      errorText: ['errorText'],
      menuList: ['menuList'],
      menuPaper: ['menuPaper'],
    },
    getTextField2UtilityClass,
    classes
  );
};
