import { useMutation as useMutationQuery, UseMutationResult, MutationFunction, UseMutationOptions } from 'react-query';

interface Return<TData, TError, TVariables, TContext>
  extends Omit<UseMutationResult<TData, TError, TVariables, TContext>, 'isLoading' | 'error' | 'data'> {
  response?: TData
  isProcessing: boolean
  processError: TError | null
}

export default function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>
): Return<TData, TError, TVariables, TContext> {
  const { isLoading, error, data, ...rest } = useMutationQuery<TData, TError, TVariables, TContext>(mutationFn, options);

  return {
    isProcessing: isLoading,
    response: data,
    processError: error,
    ...rest,
  };
}
