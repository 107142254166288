import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import AppBar, { AppBarControls } from '@o2/ui/AppBar';
import { Box } from '@o2/ui';
import LogoO2Dobijeni from '@o2/icons/LogoO2Dobijeni';
import { Theme } from '@mui/material/styles';
import O2AppLogo from '@o2/icons/O2AppLogo';

import useAuth from '@hooks/useAuth';

import Controls from './Controls';
import UserPopover, { UserPopoverProps } from './UserPopover';

const Header: FC = () => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [ popoverElm, setPopoverElm ] = useState<UserPopoverProps['popoverElm']>(null);
  const { isLoggedIn } = useAuth();

  const handlePopoverClose = (e?: MouseEvent | TouchEvent | React.MouseEvent) => {
    e?.preventDefault();
    setPopoverElm(null);
  };
  const handlePopoverOpen = (i: string) => (e: React.MouseEvent) => {
    if (!isLoggedIn) {
      return handlePopoverClose();
    }

    if (popoverElm?.id === i) {
      return handlePopoverClose();
    }

    setPopoverElm({
      id: i, // we only want to open one specific popover with this ID (female version: we don't know what we want, but we want it now!)
      target: e.currentTarget, // we want it open near clicked button
    });
  };

  const resolveLogo = () => {
    if (window.location.href.includes('automaticka-platba')) {
      return (
        <Box>
          <O2AppLogo
            data-elm="11.90.2_001"
            onClick={() => window.location.href = 'https://www.o2.cz/'}
          />
        </Box>
      );
    }

    return (
      <Link to="/">
        <Box paddingTop={1}>
          <LogoO2Dobijeni height={smDown ? '21px' : '32px'} />
        </Box>
      </Link>
    );
  };

  return (
    <AppBar internalBar={false}>
      {resolveLogo()}
      <AppBarControls>
        <Controls
          handlePopoverOpen={handlePopoverOpen}
        />
      </AppBarControls>
      <UserPopover
        id="userPopover"
        popoverElm={popoverElm}
        handlePopoverClose={handlePopoverClose}
      />
    </AppBar>
  );
};

export default Header;
