import React, { FC, MouseEvent } from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@o2/ui/Avatar';
import Box from '@o2/ui/Box';
import Button from '@o2/ui/Button';
import Popper from '@o2/ui/Popper';
import Typography from '@o2/ui/Typography';
import Stack from '@o2/ui/Stack';
import PersonIcon from '@mui/icons-material/Person';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { getApiPath } from 'utilities/requests';
import { useTranslation } from 'react-i18next';
import useAuth from '@hooks/useAuth';
import { LOGOUT_URL } from '@configurations/constants/api';
import useCodeLists from '@hooks/useCodeLists';

export interface UserPopoverProps {
  id: string
  popoverElm: {
    /**
     * What popover to open.
     */
    id: string
    /**
     * Where to open it (anchor).
     */
    target: Element
  } | null
  handlePopoverClose: (e: MouseEvent | React.MouseEvent | TouchEvent) => void
}

const UserPopover: FC<UserPopoverProps> = ({ id, popoverElm, handlePopoverClose }) => {
  const { t } = useTranslation();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { userDisplayName, subscriberType } = useAuth();
  const { getItems } = useCodeLists();
  const [ homePOP, homePRP ] = getItems(
    'homePOP',
    'homePRP'
  );

  const handleHome = () => {
    if (!subscriberType) return;
    if (subscriberType === 'O2PRP') window.location.href = homePRP;
    if (subscriberType === 'O2POP') window.location.href = homePOP;
  };

  const handleSettings = () => {
    if (!subscriberType) return;
    if (subscriberType === 'O2PRP') window.location.href = getApiPath('/deeplink/public/v1/deeplink?command=PRPKOSET');
    if (subscriberType === 'O2POP') window.location.href = getApiPath('/deeplink/public/v1/deeplink?command=PRPDASH');
  };

  return (
    <Popper
      id={id}
      open={popoverElm?.id === id}
      anchorEl={popoverElm?.target}
      PaperProps={{
        mt: '-6px',
      }}
      onClickAway={(e:any) => {
        handlePopoverClose && handlePopoverClose(e);
      }}
    >
      <Box m={2} sx={{ textAlign: 'center' }}>
        <Avatar sx={{ display: 'inline-flex', width: 62, height: 62 }}>
          <PersonIcon fontSize="large" />
        </Avatar>
        <Typography variant="h4" component="div" my={2}>
          {userDisplayName}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={'8px'}
        >
          <Button fullWidth={smDown} size="small" onClick={handleHome} startIcon={<HomeOutlinedIcon />}>
            {t('topup.header.userAction.home')}
          </Button>
          <Button fullWidth={smDown} size="small" onClick={handleSettings} startIcon={<SettingsOutlinedIcon />}>
            {t('topup.header.userAction.settings')}
          </Button>
          <Button
            // eslint-disable-next-line
            onClick={(e) => {
              handlePopoverClose && handlePopoverClose(e);
              window.localStorage.setItem('REACT_QUERY_OFFLINE_CACHE', '');
              window.location.href = getApiPath(LOGOUT_URL);
            }}
            fullWidth={smDown}
            size="small"
            startIcon={<LogoutOutlinedIcon />}
          >
            {t('topup.header.userAction.logout')}
          </Button>
        </Stack>
      </Box>
    </Popper>
  );
};

export default UserPopover;
