import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import ToggleButtonGroup from '@o2/ui/ToggleButtonGroup';

import IProps from './types';

const FormToggleButtonGroup: FC<IProps> = ({ name, control, children, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ToggleButtonGroup
          {...rest}
          {...field}
        >
          {children}
        </ToggleButtonGroup>
      )}
    />
  );
};

export default FormToggleButtonGroup;
