import { useTopUpByCard } from '@hooks/useAPI';
import { Alert } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';

const AlertAutomaticRechargeActivated: FC<IProps> = () => {
  const [ show, setShow ] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    queryResponse,
  } = useTopUpByCard();

  useEffect(() => {
    if (queryResponse && queryResponse.resultCode === '-12') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [ queryResponse ]);

  if (show) {
    return (
      <Alert severity="error">
        {t('topup.online.alreadyActivated')}
      </Alert>
    );
  } else {
    return <></>;
  }
};

export default AlertAutomaticRechargeActivated;
