import { forwardRef, MouseEvent, useRef, useState, ChangeEvent, ReactNode } from 'react';
import { SelectChangeEvent } from '../Select';
import MuiFormControl from '../FormControl';
import Stack from '../Stack';
import Typography from '../Typography';
import OutlinedInput from '../OutlinedInput';
import FormLabel from '../FormLabel';
import Box from '../Box';
import { OverridableStringUnion } from '@mui/types';
import {
  FormControlProps as MuiFormControlProps,
  FormControlPropsColorOverrides,
  FormControlPropsSizeOverrides,
} from '@mui/material/FormControl';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { InputLabelProps } from '@mui/material/InputLabel';
import { InputProps } from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { unstable_useId as useId } from '@mui/utils';
import palette from '../theme/palette';
import colors from '../theme/colors';
import { ColorList } from '../theme/colorList';
import pxToRem from '../theme/pxToRem';
import { INPUT_WIDTH } from '../theme/constants';
import clsx from 'clsx';
import textField2Classes, { useTextField2Classes } from './textField2Classes';
import { menuItemClasses } from '@mui/material/MenuItem';

type FormControlWidthType = OverridableStringUnion<
  'xs' | 'date' | 'small' | 'medium' | 'large',
  FormControlPropsSizeOverrides
>;

type FormControlColorType = OverridableStringUnion<ColorList, FormControlPropsColorOverrides>;

type TextFieldWidthType = FormControlWidthType;

type TextFieldColorType = FormControlColorType;

type InputMoreType = {
  id?: string;
  notched?: boolean;
  'aria-describedby'?: string;
};

export interface TextFieldElementIds {
  root?: string;
  label?: string;
  labelNote?: string;
  tooltip?: string;
  input?: string;
  errorText?: string;
  helperText?: string;
}

export type TextFieldProps = MuiTextFieldProps & {
  color?: TextFieldColorType;

  /**
   * Modifies TextField width.
   */
  size?: TextFieldWidthType;

  /**
   * Validation messages. On original MUI TextField component error messages shares the same element with `helperText`.
   * Here we separate `errorText` and `helperText` to let them display both.
   */
  errorText?: ReactNode;

  /**
   * Additional text bellow `label`.
   */
  labelNote?: ReactNode;

  elmIds?: TextFieldElementIds;

  /**
   * Tooltip element on the right side of `label`.
   */
  tooltip?: ReactNode;

  /**
   * Adjust vertical alignment of side by side input and button. If prop is set, `TextField's` input and adjacent button looks like on the same line.
   */
  adjacent?: boolean;
};

interface FormControlProps extends MuiFormControlProps {
  color?: FormControlColorType;
  size?: FormControlWidthType;
}

interface TextFieldFormControlProps extends FormControlProps {
  color?: TextFieldColorType;
  size?: TextFieldWidthType;
  adjacent?: boolean;
  label?: ReactNode;
  labelNote?: ReactNode;
  errorText?: ReactNode;
  helperText?: ReactNode;
  elmIds?: TextFieldElementIds;
}

interface SelectOwnerState {
  placeholderVisibility: boolean;
}

interface SelectWithOwnerState extends TextFieldFormControlProps {
  ownerState: SelectOwnerState;
}

const PLACEHOLDER_TRUNCATE_OFFSET = '32px';

const StyledFormControl = styled(MuiFormControl, {
  name: 'O2TextField',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'adjacent' && prop !== 'label' && prop !== 'labelNote',
})<TextFieldFormControlProps>(({ theme, adjacent, label, labelNote, size, fullWidth }) => ({
  gap: theme.spacing(0.5),
  width: fullWidth ? INPUT_WIDTH.FULL : INPUT_WIDTH.MEDIUM,
  ...(size === 'xs' && {
    width: INPUT_WIDTH.XS,
  }),
  ...(size === 'date' && {
    width: INPUT_WIDTH.DATE,
  }),
  ...(size === 'small' && {
    width: INPUT_WIDTH.SMALL,
  }),
  ...(size === 'medium' && {
    width: INPUT_WIDTH.MEDIUM,
  }),
  ...(size === 'large' && {
    width: INPUT_WIDTH.LARGE,
  }),
  ...(adjacent && {
    '&& + *': {
      ...(label && {
        marginTop: 23,
      }),
      ...(label &&
        labelNote && {
          marginTop: 43,
        }),
    },
  }),
}));

const StyledInput = styled(OutlinedInput, {
  name: 'O2TextField',
  slot: 'Input',
})<{ ownerState: InputProps }>(({ fullWidth, ownerState: { multiline, disabled } }) => ({
  backgroundColor: palette.background.white,
  borderRadius: 6,
  '& > fieldset': {
    borderColor: colors.transparentGray[200],
  },
  '&:hover:not(.Mui-focused) > fieldset': {
    borderColor: colors.transparentGray[200],
  },
  '&.Mui-focused:hover > fieldset': {
    borderWidth: 1.5,
  },
  '&.Mui-focused:not(:hover) > fieldset': {
    borderWidth: 1,
  },
  '&.Mui-error:hover > fieldset': {
    borderColor: palette.error.main,
  },
  ...(disabled && {
    backgroundColor: colors.transparentWhite[900],
    '& .MuiInputAdornment-root': {
      color: palette.text.disabled,
    },
  }),
  ...(multiline && {
    padding: 0,
  }),
  ...(fullWidth && {
    width: INPUT_WIDTH.FULL,
  }),
  '& input[type="search"]::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
  },
}));

const StyledInputLabel = styled(FormLabel, { name: 'O2TextField', slot: 'Label' })<{ ownerState: InputLabelProps }>(
  ({ theme, ownerState: { disabled } }) => ({
    color: palette.text.primary,
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: pxToRem(19),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    /* height as lineHeight. */
    minHeight: pxToRem(19),
    [theme.breakpoints.down('xl')]: {
      fontSize: pxToRem(14),
    },
    ...(disabled && {
      color: palette.text.disabled,
    }),
    '& + .MuiSvgIcon-root': {
      color: palette.text.secondary,
      transform: 'translateY(2px)',
    },
    ...(disabled && {
      '& + .MuiSvgIcon-root': {
        color: palette.text.disabled,
        transform: 'translateY(2px)',
      },
    }),
    '&.Mui-focused, &.Mui-error ': {
      color: 'inherit',
    },
  })
);

const StyledInputLabelNote = styled(Typography, { name: 'O2TextField', slot: 'Label' })<{
  ownerState: InputLabelProps;
}>(({ children, ownerState: { disabled } }) => ({
  color: palette.text.secondary,
  fontSize: pxToRem(12),
  lineHeight: pxToRem(16),
  minHeight: pxToRem(16),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(disabled && {
    color: palette.text.disabled,
  }),
  /**
   * If labelNote is empty and used just as a space buffer, swap the order with `label`.
   */
  ...(children === ' ' && {
    order: -1,
  }),
}));

const ErrorText = styled(Typography, { name: 'O2TextField', slot: 'ErrorText' })(() => ({
  margin: 0,
  color: palette.error.dark,
  fontSize: pxToRem(12),
}));

const HelperText = styled('div', { name: 'O2TextField', slot: 'HelperText' })<{ ownerState: InputLabelProps }>(
  ({ ownerState: { disabled } }) => ({
    margin: 0,
    color: palette.text.secondary,
    fontSize: pxToRem(12),
    ...(disabled && {
      color: palette.text.disabled,
    }),
  })
);

const Placeholder = styled('div', { name: 'O2TextField', slot: 'Placeholder' })<SelectWithOwnerState>(
  ({ theme, size, ownerState: { placeholderVisibility } }) => ({
    color: palette.text.disabled,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: theme.zIndex.drawer,
    maxWidth: `calc(100% - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    ...(size === 'xs' && {
      maxWidth: `calc(${INPUT_WIDTH.XS} - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    }),
    ...(size === 'date' && {
      maxWidth: `calc(${INPUT_WIDTH.DATE} - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    }),
    ...(size === 'small' && {
      maxWidth: `calc(${INPUT_WIDTH.SMALL} - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    }),
    ...(size === 'medium' && {
      maxWidth: `calc(${INPUT_WIDTH.MEDIUM} - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    }),
    ...(size === 'large' && {
      maxWidth: `calc(${INPUT_WIDTH.LARGE} - ${PLACEHOLDER_TRUNCATE_OFFSET})`,
    }),
    ...(!placeholderVisibility && {
      display: 'none',
    }),
  })
);

// Pass className prop to allow styling elements outside of DOM hierarchy (Paper).
// More info: https://mui.com/material-ui/guides/interoperability/#portals
const StyledSelect = styled(({ className, ...props }: MuiSelectProps) => {
  const { MenuProps, ...other } = props;

  return (
    <MuiSelect {...other} classes={{ select: className }} MenuProps={{ classes: { paper: className }, ...MenuProps }} />
  );
})`
  &.${textField2Classes.select}.MuiSelect-select.Mui-expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.${textField2Classes.select}.Mui-expanded ~ fieldset {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
  }
  &.${textField2Classes.menuPaper} {
    border-width: 1px;
    border-style: solid;
    border-color: ${palette.primary.main};
    border-top-color: ${colors.transparentGray[100]};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
    width: min-content;

    &.Mui-error {
      border-color: ${palette.error.main};
      border-top-color: ${colors.transparentGray[100]};
    }
    & .${menuItemClasses.root} {
      white-space: normal;
      color: ${palette.text.secondary};
    }
    & .${menuItemClasses.root}:hover {
      background-color: ${colors.brandPrimary[100]};
      color: ${palette.text.secondary};
    }
    & .${menuItemClasses.focusVisible} {
      background-color: ${palette.common.white};
    }
    & .${menuItemClasses.selected} {
      background-color: ${colors.transparentGray[100]};
      color: ${palette.text.primary};
    }
    & .${menuItemClasses.selected}:hover, & .${menuItemClasses.focusVisible}:hover {
      background-color: ${colors.brandPrimary[100]};
    }
  }
`;

const TextField2 = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  const {
    labelNote,
    errorText,
    adjacent,
    tooltip,
    autoComplete,
    autoFocus = false,
    children,
    color = 'primary',
    defaultValue,
    elmIds,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    id: idOverride,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    maxRows,
    minRows,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    type,
    value,
    ...otherProps
  } = props;

  const utilityClasses = useTextField2Classes();
  const placeholderRef = useRef<HTMLInputElement>(null);
  const selectInputRef = useRef<HTMLInputElement>(null);
  const [placeholderVisibility, setPlaceholderVisibility] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);
  const id = useId(idOverride);
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const errorTextId = errorText && id ? `${id}-error-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  const InputMore: InputMoreType = {};

  if (InputLabelProps && typeof InputLabelProps.shrink !== 'undefined') {
    InputMore.notched = InputLabelProps.shrink;
  }

  if (select) {
    // unset defaults from textbox inputs
    if (!SelectProps || !SelectProps.native) {
      InputMore.id = undefined;
    }
    InputMore['aria-describedby'] = undefined;
  }

  const inputElement = (
    <StyledInput
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      data-elm={elmIds?.input}
      defaultValue={defaultValue}
      multiline={multiline}
      fullWidth={fullWidth}
      name={name}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      type={type}
      value={value}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      inputProps={inputProps}
      {...InputMore}
      {...InputProps}
      ownerState={{ multiline: multiline, disabled: props.disabled }}
      className={clsx(utilityClasses.input)}
    />
  );

  const handleClick = (event: MouseEvent) => {
    if (event.currentTarget === event.target) {
      selectInputRef?.current?.focus();
    }

    setOpenSelect(true);
  };

  const selectChangeHandler = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
    const value = event.target.value;
    const e = event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const visibility = Array.isArray(value) ? !value.length : value === undefined;

    setPlaceholderVisibility(visibility);

    // Call onChange prop handler
    if (onChange) {
      onChange(e);
    }

    // Call SelectProps.onChange prop handler
    SelectProps?.onChange?.(event, child);
  };

  return (
    <StyledFormControl
      ref={ref}
      color={color}
      data-elm={elmIds?.root}
      fullWidth={fullWidth}
      adjacent={adjacent}
      required={required}
      label={label}
      labelNote={labelNote}
      className={clsx(utilityClasses.root)}
      {...otherProps}
    >
      {!!label && (
        <>
          <Stack direction="row" alignItems="baseline" gap={1} className={clsx(utilityClasses.labelContainer)}>
            <StyledInputLabel
              htmlFor={id}
              id={inputLabelId}
              data-elm={elmIds?.label}
              ownerState={{ disabled: props.disabled }}
              className={clsx(utilityClasses.label)}
            >
              {label}
            </StyledInputLabel>
            {tooltip}
          </Stack>
          {!!labelNote && (
            <StyledInputLabelNote
              data-elm={elmIds?.labelNote}
              color={palette.text.secondary}
              ownerState={{ disabled: props.disabled }}
              variant="body2"
              className={clsx(utilityClasses.labelNote)}
            >
              {labelNote}
            </StyledInputLabelNote>
          )}
        </>
      )}
      <Box position="relative">
        {select ? (
          <StyledSelect
            aria-describedby={helperTextId}
            data-elm={elmIds?.input}
            inputRef={selectInputRef}
            id={id}
            input={inputElement}
            open={openSelect}
            value={value}
            {...SelectProps}
            onChange={selectChangeHandler}
            onClose={(event) => {
              setOpenSelect(false);
              SelectProps?.onClose?.(event);
            }}
            onOpen={(event) => {
              setOpenSelect(true);
              SelectProps?.onOpen?.(event);
            }}
            className={clsx(utilityClasses.select, openSelect && 'Mui-expanded')}
            MenuProps={{
              elevation: 0,
              PaperProps: {
                className: clsx(utilityClasses.menuPaper, props.error && 'Mui-error'),
              },
              MenuListProps: {
                className: clsx(utilityClasses.menuList),
              },
              ...SelectProps?.MenuProps,
            }}
          >
            {children}
          </StyledSelect>
        ) : (
          inputElement
        )}
        {select && (
          <Placeholder
            ref={placeholderRef}
            onClick={handleClick}
            size={props.size}
            ownerState={{ placeholderVisibility: placeholderVisibility }}
          >
            {placeholder}
          </Placeholder>
        )}
      </Box>
      {errorText && (
        <ErrorText data-elm={elmIds?.errorText} id={errorTextId} className={clsx(utilityClasses.errorText)}>
          {errorText}
        </ErrorText>
      )}
      {helperText && (
        <HelperText
          data-elm={elmIds?.helperText}
          id={helperTextId}
          ownerState={{ disabled: props.disabled }}
          className={clsx(utilityClasses.helperText)}
          {...FormHelperTextProps}
        >
          {helperText}
        </HelperText>
      )}
    </StyledFormControl>
  );
});

TextField2.displayName = 'TextField2';

export default TextField2;
