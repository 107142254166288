import React, { FC, ReactNode, MouseEvent } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Theme } from '@mui/material/styles';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppBarButton from '@o2/ui/AppBar/AppBarButton';
import AppBarIconButton from '@o2/ui/AppBar/AppBarIconButton';
import Box from '@o2/ui/Box';
import { useTranslation } from 'react-i18next';

import { redirectToLoginPageExternalForced } from '@utilities/redirects';
import useAuth from '@hooks/useAuth';

const TruncateText = styled('div')(() => ({
  flex: 1,
  minWidth: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

interface ResponsiveButtonProps {
  children: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  dense?: boolean
  onClick?: (e: MouseEvent) => void
}

const ResponsiveButton: FC<ResponsiveButtonProps> = ({ children, startIcon, endIcon, dense, onClick, ...other }) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // Popover one anchor element target. That's why we pack it into div.
  return (
    <div style={{ display: 'flex' }} onClick={onClick}>
      {mdUp ? (
        <AppBarButton startIcon={startIcon} endIcon={endIcon} variant={dense ? 'dense' : undefined} {...other}>
          {children}
        </AppBarButton>
      ) : (
        <AppBarIconButton dense={dense} {...other}>
          {startIcon}
        </AppBarIconButton>
      )}
    </div>
  );
};

interface ControlsProps {
  handlePopoverOpen: (popoverId: string) => (e: MouseEvent) => void
}

const Controls: FC<ControlsProps> = ({ handlePopoverOpen }) => {
  const { t } = useTranslation();
  const { isLoggedIn, userDisplayName } = useAuth();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <>
      {isLoggedIn ? (
        <ResponsiveButton
          startIcon={<PersonOutlineOutlinedIcon />}
          endIcon={<ExpandMoreIcon />}
          aria-describedby="userPopover"
          aria-haspopup="true"
          onClick={handlePopoverOpen('userPopover')}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
              fontSize: '0.75rem',
              lineHeight: 'normal',
              maxWidth: '180px',
            }}
          >
            <TruncateText>{userDisplayName}</TruncateText>
          </Box>
        </ResponsiveButton>
      ) : (
        <AppBarButton
          variant={mdUp ? 'dense' : undefined}
          onClick={() => redirectToLoginPageExternalForced()}
        >
          {t('topup.header.login')}
        </AppBarButton>
      )}
    </>
  );
};

export default Controls;
