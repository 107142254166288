import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import TextField from '@o2/ui/TextField2';

import IProps from './types';

const FormTextField: FC<IProps> = ({ name, control, ...rest }) => {
  return <Controller name={name} control={control} render={({ field }) => <TextField {...rest} {...field} />} />;
};

export default FormTextField;
