export default (phoneNumber: string) => {
  // Return an empty string if the phone number is falsy
  if (!phoneNumber) return '';

  // Use a regular expression to remove any country prefixes (e.g. +44, 0044, 44) from the phone number
  const strippedNumber = phoneNumber.replace(/^(\+|00|0)?[0-9]{2}/, '');

  // If the stripped number is longer than 9 digits, return the last 9 digits
  if (strippedNumber.length > 9) {
    return strippedNumber.substr(strippedNumber.length - 9);
  }
  // If regex go crazy and cut it much for some reason
  if (strippedNumber.length < 9) {
    return phoneNumber.substr(phoneNumber.length - 9);
  }

  // Otherwise, return the stripped phone number
  return strippedNumber;
};
