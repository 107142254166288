import { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Theme } from '@mui/material/styles';
import { PAGE_NAVIGATION_WIDTH } from '../constants/layout';

const AppLayoutContentNavigationRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'ContentNavigation',
})(({ theme }) => ({
  width: PAGE_NAVIGATION_WIDTH,
  flex: `0 0 ${PAGE_NAVIGATION_WIDTH}px`,
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
  position: 'relative',
  [theme.breakpoints.between('xs', 'md')]: {
    width: '100%',
    flex: 0,
  },
}));

const AppLayoutContentNavigationFixed = styled('div', {
  name: 'O2AppLayout',
  slot: 'ContentNavigationFixed',
})(({ theme }) => ({
  position: 'fixed',
  width: PAGE_NAVIGATION_WIDTH,
  overflowY: 'auto',
  // TODO: FIX ME
  height: '80%',
  [theme.breakpoints.between('xs', 'md')]: {
    width: '100%',
    position: 'relative',
  },
}));

/**
 * @deprecated
 * @constructor
 */
const AppLayoutContentNavigation: FC = (props) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return smUp ? (
    <AppLayoutContentNavigationRoot>
      <AppLayoutContentNavigationFixed>{props.children}</AppLayoutContentNavigationFixed>
    </AppLayoutContentNavigationRoot>
  ) : null;
};

export default AppLayoutContentNavigation;
