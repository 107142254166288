import { styled } from '@mui/material/styles';
import { FC } from 'react';

const AppLayoutContentMainRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'ContentMain',
})(() => ({
  flexGrow: 1,
  minWidth: 0,
}));

/**
 * @deprecated
 * @constructor
 */
const AppLayoutContentMain: FC = (props) => {
  return <AppLayoutContentMainRoot>{props.children}</AppLayoutContentMainRoot>;
};

export default AppLayoutContentMain;
