import { FC, MouseEventHandler } from 'react';
import LogoGooglePlay from '@o2/icons/LogoGooglePlay';
import { styled } from '@mui/material';

interface GooglePlayButton {
  onClick: MouseEventHandler;
}

const Button = styled('div', {
  name: 'GooglePlayButton',
  slot: 'Root',
})(() => ({
  height: '40px',
  minWidth: '135px',
  cursor: 'pointer',
}));

const GooglePlayButton: FC<GooglePlayButton> = (props) => {
  return (
    <Button {...props}>
      <LogoGooglePlay />
    </Button>
  );
};

export default GooglePlayButton;
