import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

interface AppLayoutPageProps {
  isInternalPage?: boolean;
  children: ReactNode;
}

const AppLayoutPageRoot = styled('div', {
  name: 'O2AppLayout',
  slot: 'Page',
  shouldForwardProp: (prop) => prop !== 'isInternalPage',
})<AppLayoutPageProps>(({ theme, isInternalPage = false }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  ...(isInternalPage && {
    backgroundColor: theme.palette.background.internal,
  }),
}));

/**
 * @deprecated
 * @constructor
 */
const AppLayoutPage: FC<AppLayoutPageProps> = (props) => {
  const { isInternalPage, children, ...other } = props;

  return (
    <AppLayoutPageRoot isInternalPage={isInternalPage} {...other}>
      {children}
    </AppLayoutPageRoot>
  );
};

export default AppLayoutPage;
