import { FC } from 'react';
import WhiteLogo from './assets/logo-dobijeni/O2Dobijeni.svg';

export interface LogoO2DobijeniProps {
  width?: string | number;
  height?: string | number;
}

const LogoO2Dobijeni: FC<LogoO2DobijeniProps> = ({ width, height }) => {
  return (
    <img
      src={WhiteLogo}
      alt="O2 dobíjení"
      style={{ width: width ?? 'auto', height: height ?? 'auto', maxWidth: '100%', maxHeight: '100%' }}
    />
  );
};

export default LogoO2Dobijeni;
