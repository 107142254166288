import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@o2/ui/IconButton';
import clsx from 'clsx';
import { FC, MouseEventHandler, ReactNode } from 'react';

import { unstable_composeClasses as composeClasses } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StatusError from '@o2/icons/StatusError';
import StatusErrorSmall from '@o2/icons/StatusErrorSmall';
import StatusInfo from '@o2/icons/StatusInfo';
import StatusInfoSmall from '@o2/icons/StatusInfoSmall';
import StatusInternalWarning from '@o2/icons/StatusInternalWarning';
import StatusInternalWarningSmall from '@o2/icons/StatusInternalWarningSmall';
import StatusSuccess from '@o2/icons/StatusSuccess';
import StatusSuccessSmall from '@o2/icons/StatusSuccessSmall';
import StatusWarning from '@o2/icons/StatusWarning';
import StatusWarningSmall from '@o2/icons/StatusWarningSmall';
import Box from '@o2/ui/Box';
import Paper, { PaperProps } from '@o2/ui/Paper';
import Stack from '@o2/ui/Stack';
import { ColorList } from '@o2/ui/theme/colorList';
import Typography from '@o2/ui/Typography';

import { getInfoMessageUtilityClass } from './infoMessageClasses';

export enum Orientation {
  Vertical,
  Horizontal,
}

export interface InfoMessageProps extends Omit<PaperProps, 'title'> {
  /**
   * The `variant` prop sets both the Paper accent color and the icon (which can be overridden with `icon`).
   */
  variant: Exclude<ColorList, 'primary' | 'secondary' | 'price'>;

  /**
   * If truthy, the left accent (thick border) will be disabled.
   */
  disableAccent?: boolean;

  /**
   * If truthy, `controls` will not be horizontally offset by the icon size (the controls container will start directly at the
   * left edge).
   */
  disableControlsOffset?: boolean;

  children?: ReactNode;

  /**
   * If not provided, an icon will be automatically selected according to the `variant`.
   */
  icon?: ReactNode;

  /**
   * If true, the icon will not be displayed.
   */
  hideIcon?: boolean;

  /**
   * If a string, it will be wrapped in a h6 Typography (unless `disableTitleStyling` is true). For customization, you can
   * pass any ReactNode, such as your own Typography instance.
   */
  title?: ReactNode;

  /**
   * If a string, it will be wrapped in a Typography (unless `disableDescriptionStyling` is true). For customization, you can
   * pass any ReactNode, such as your own Typography instance.
   */
  description?: ReactNode;

  /**
   * Disable styling the passed `description` as body text.
   *
   * Defaults to false (= does style) if `description` is a string,
   * true otherwise (if you pass a ReactElement, for example, it will not be styled).
   *
   * Useful to override for example when composing the text content from several `<span/>`s.
   */
  disableDescriptionStyling?: boolean;

  /**
   * Disable styling the passed `title` as a heading.
   *
   * Defaults to false (= does style) if `title` is a string,
   * true otherwise (if you pass a ReactElement, for example, it will not be styled).
   *
   * Useful to override for example when composing the text content from several `<span/>`s.
   */
  disableTitleStyling?: boolean;

  /**
   * Controls displayed to the right of heading/description (on XL) or under them (on XS).
   */
  controls?: ReactNode;

  /**
   * What axis to distribute children on. Only applies on non-mobile (initially >md) viewports; on mobile devices, the
   * orientation is always vertical.
   */
  orientation?: Orientation;

  /**
   * Receives MouseClickEventHandler. If this handler is passed, the CloseButton is displayed.
   */
  onClose?: MouseEventHandler;
}

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
    icon: ['icon'],
    controls: ['controls'],
  };

  const composedClasses = composeClasses(slots, getInfoMessageUtilityClass, undefined);

  return {
    ...composedClasses,
  };
};

const getIcon = (variant: InfoMessageProps['variant'], small: boolean) => {
  switch (variant) {
    case 'warning':
      return small ? <StatusWarningSmall /> : <StatusWarning />;
    case 'error':
      return small ? <StatusErrorSmall /> : <StatusError />;
    case 'success':
      return small ? <StatusSuccessSmall /> : <StatusSuccess />;
    case 'info':
      return small ? <StatusInfoSmall /> : <StatusInfo />;
    case 'internal':
      return small ? <StatusInternalWarningSmall /> : <StatusInternalWarning />;
  }
};

const IconBox = styled(Box)(({ theme }) => ({
  '&, & > *': {
    width: 48,
    height: 48,
    minWidth: 48,
    minHeight: 48,
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32,
      minWidth: 32,
      minHeight: 32,
    },
  },
}));

const CloseButtonWrapper = styled(Stack)(() => ({
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'end',
}));

const FullSizeBox = styled(Box)(() => ({
  flexGrow: 1,
  '& > *': {
    height: '100%',
  },
}));

const InfoMessage: FC<InfoMessageProps> = ({
  variant,
  controls,
  description,
  disableAccent = false,
  disableControlsOffset,
  title,
  hideIcon = false,
  icon,
  children,
  orientation = Orientation.Horizontal,
  disableDescriptionStyling = typeof description !== 'string',
  disableTitleStyling = typeof title !== 'string',
  onClose,
  ...paperProps
}) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const titleEl = disableTitleStyling ? (
    title
  ) : (
    <Typography variant={mdDown ? 'h5' : 'h6'} component="span">
      {title}
    </Typography>
  );

  const descEl = disableDescriptionStyling ? description : <Typography>{description}</Typography>;

  const classes = useUtilityClasses();

  return (
    <Paper
      accentColor={disableAccent ? undefined : variant !== 'info' ? variant : 'primary'}
      p={2}
      color={variant === 'internal' ? 'internal' : 'white'}
      className={clsx(classes.root)}
      {...paperProps}
    >
      <Stack direction="row" spacing={2}>
        <Stack
          flexGrow={1}
          direction={{
            xs: 'column',
            md: orientation === Orientation.Horizontal ? 'row' : 'column',
          }}
          spacing={2}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            {!hideIcon && <IconBox className={clsx(classes.icon)}>{icon ?? getIcon(variant, mdDown)}</IconBox>}
            <Stack direction="column" spacing={0.5} justifyContent={!(titleEl && descEl) ? 'center' : undefined} flexGrow={1}>
              {children ? (
                children
              ) : (
                <>
                  {titleEl}
                  {descEl}
                </>
              )}
            </Stack>
          </Stack>
          {controls && (
            <Stack direction="row" spacing={2}>
              {!smDown && orientation === Orientation.Vertical && !disableControlsOffset && <IconBox />}
              <FullSizeBox className={clsx(classes.controls)}>{controls}</FullSizeBox>
            </Stack>
          )}
        </Stack>
        {onClose && (
          <CloseButtonWrapper>
            <IconButton
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </CloseButtonWrapper>
        )}
      </Stack>
    </Paper>
  );
};

export default InfoMessage;
