import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { format } from 'date-fns';

import { useGetKahlMessage } from '@hooks/useAPI';
import { APP_ID_UC } from '@configurations/constants';
import { IKahlQueryParams, IKahlIdStore, IKahlMessageQuery } from '@configurations/types';
import { IMessage } from '@components/blocks/FlashMessage/types';

const useKahlMessages = () => {
  const { i18n } = useTranslation();
  const { mutation: { mutate: getKahlMessage } } = useGetKahlMessage();
  const { data: kahlIdStore } = useQuery<IKahlIdStore>('kahlIdStore');
  const { data: kahlMessageQuery } = useQuery<IKahlMessageQuery>('kahlMessage');
  const [ message, setMessage ] = useState<IMessage | null>(null);

  const getKahlColor = (severity: string, isInternal: boolean) => {
    if (isInternal) {
      return 'internal';
    } else {
      if (severity === '2' || severity === '3') {
        return 'success';
      } else if (severity === '3') {
        return 'warning';
      } else {
        return 'error';
      }
    }
  };

  useEffect(() => {
    if (kahlIdStore?.kahlMessageId) {
      const queryParams: IKahlQueryParams = {
        infoMessageIds: kahlIdStore.kahlMessageId,
        timestamp: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
        sourceApplication: APP_ID_UC,
        locale: i18n.language,
      };
      getKahlMessage(queryParams);
    }
  }, [ kahlIdStore ]);

  useEffect(() => {
    if (kahlMessageQuery?.data) {
      for (let i = 0; i < kahlMessageQuery?.data.length; i++) {
        const kahlMessageData = kahlMessageQuery?.data[i];
  
        setMessage({
          text: kahlMessageData.text,
          color: getKahlColor(
            kahlMessageData.errorType,
            kahlMessageData.isInternal
          ),
          autoClose: kahlMessageData.errorType === '1' ? 10 : 5,
          hasCloseBtn: parseInt(kahlMessageData.errorType) <= 3,
        });
      }
    }
  }, [ kahlMessageQuery ]);

  return message;
};

export default useKahlMessages;
