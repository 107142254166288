import React, { FC } from 'react';

import useKahlMessages from '@hooks/useKahlMessages';
import FlashMessage from '@components/blocks/FlashMessage';

import { Props } from './types';

const RootLayout: FC<Props> = ({ children }) => {
  const kahlMessage = useKahlMessages();

  return (
    <>
      {children}
      <FlashMessage message={kahlMessage} />
    </>
  );
};

export default RootLayout;
